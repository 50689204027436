import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async'
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import ChangedPasswordModal from '../../modals/ChangePasswordModal';
import './ProfileStyles.css';
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../features/user/userSlice";
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";

const ProfileComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const name = useSelector(state => state.user.user.firstName);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/');
        }
    }, [userLoggedIn, navigate]);

    const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);
    const handleOpenModal = () => setShowChangePasswordModal(true);
    const handleCloseModal = () => setShowChangePasswordModal(false);

    const handleLogout = () => {
        dispatch(logOut());
        navigate('/');
    };

    if (!userLoggedIn) {
        return null; // or a loader/spinner
    }

    return (
        <div className="profile-page-wrapper">
            <Helmet>
                <title>{`${name} | The Sealab`}</title>
                <meta name="description"
                      content="Manage your profile, view past orders, change your password, or logout. All your cabinet management in one place."/>
                <meta name="keywords"
                      content="user profile, edit profile, view orders, change password, kitchen cabinets, closet cabinets, e-commerce, cabinet store"/>
            </Helmet>
            <NavbarComponent/>
            <div className="profile-page">
                <div className="profile-container">
                    <h1 className="profile-title">My Profile</h1>
                    <div className="profile-card">
                        <h2 className="profile-name">{capitalizeFirstLetter(name)}</h2>
                        <div className="profile-links">
                            <NavLink to="/user/edit-profile" className="profile-link">
                                <span className="link-icon">✏️</span>
                                Edit Profile
                            </NavLink>
                            <NavLink to="/user/profile/saved-orders" className="profile-link">
                                <span className="link-icon">📦</span>
                                View Saved Orders
                            </NavLink>
                            <NavLink to="#" onClick={handleOpenModal} className="profile-link">
                                <span className="link-icon">🔒</span>
                                Change Password
                            </NavLink>
                            <NavLink to="/" onClick={handleLogout} className="profile-link">
                                <span className="link-icon">🚪</span>
                                Logout
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <ChangedPasswordModal show={showChangePasswordModal} handleClose={handleCloseModal}/>
        </div>
    );
};

export default ProfileComponent;