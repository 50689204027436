import React, {useEffect, useState} from 'react';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import {useDispatch, useSelector} from "react-redux";
import {reAddToCart} from "../../features/cart/cartSlice";
import {deleteSavedOrder, getAllSavedOrders} from "../../api/OrderService";
import './SavedOrdersStyles.css';
import PdfWriterComponent from '../PdfWriterComponent/PdfWriterComponent';
import {nanoid} from "@reduxjs/toolkit";

const SavedOrdersComponent = () => {
    const [savedOrders, setSavedOrders] = useState(null);
    const [shouldFetchOrders, setShouldFetchOrders] = useState(true)
    const userLoggedIn = UserLoggedIn();
    const email = useSelector((state) => state.user.user.email);
    const token = useSelector(state => state.user.accessToken);
    const dispatch = useDispatch();
    const imageURL = process.env.REACT_APP_IMAGEURL;

    useEffect(() => {
        if (shouldFetchOrders) {
            const fetchSavedOrders = (async () => {
                try {
                    const response = await getAllSavedOrders(email, token);
                    setSavedOrders(response.data);
                    setShouldFetchOrders(false)
                } catch (e) {
                    console.error('Error fetching saved orders: ', e)
                    setSavedOrders([])
                }
            })
            fetchSavedOrders()
        }

    }, [shouldFetchOrders])

    useEffect(() => {
        if (savedOrders?.length <= 0) {
            window.scrollTo({top: 0, behavior: 'instant'})
        }
    }, [savedOrders])

    const handleOrderAgain = (tempOrderId) => {
        const ordersToAdd = savedOrders.filter(order => {
            return order.tempOrderId.toString() === tempOrderId.toString();
        });

        ordersToAdd.forEach(order => {
            dispatch(reAddToCart({...order, itemId: nanoid(), orderId: tempOrderId}));
        });
        window.scrollTo({top: 0, behavior: "instant"})
    };

    const handleDeleteSavedOrder = async (tempOrderId) => {
        try {
            const response = await deleteSavedOrder(tempOrderId, token);
            if (response.status === 200) {
                setSavedOrders(prevOrders =>
                    prevOrders.filter(order => order.tempOrderId !== tempOrderId)
                );
                setShouldFetchOrders(true);
            } else {
                console.error('Failed to delete saved order:', response.data);
            }
        } catch (error) {
            console.error('Error deleting saved order:', error);
        }
    };

    const groupedOrders = savedOrders?.reduce((acc, order) => {
        const groupId = order.tempOrderId;
        if (!acc[groupId]) {
            acc[groupId] = [];
        }
        acc[groupId].push(order);
        return acc;
    }, {});

    return (
        <div>
            {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
            <div className="container my-4 min-vh-100">
                {savedOrders === null ? (
                    <div className="text-center">Loading...</div>
                ) : savedOrders?.length === 0 ? (
                    <div className="text-center">
                        <h5><strong>You do not have any saved orders at this time.</strong></h5>
                    </div>
                ) : (
                    Object.entries(groupedOrders).map(([tempOrderId, orders]) => (
                        <div key={tempOrderId} className="row mb-4">
                            <div className="col">
                                <div className="card" id={`order-${tempOrderId}`}>
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="card-title">Temporary Order ID: {tempOrderId}</h5>
                                        <PdfWriterComponent orderDetails={orders} imageURL={imageURL}/>
                                    </div>
                                    {orders.map((order, index) => (
                                        <div key={index} className="card-body d-flex"
                                             id={`order-item-${order.serialNumber}`}>
                                            <div className="order-details flex-grow-1">
                                                <p className="card-text">
                                                    <strong>Item Name:</strong> {order?.positionName}<br/>
                                                    <strong>Serial Number:</strong> {order.serialNumber}<br/>
                                                    <strong>Height:</strong> {order.height} in<br/>
                                                    <strong>Width:</strong> {order.width} in<br/>
                                                    <strong>Depth:</strong> {order.depth} in<br/>
                                                    <strong>Front Material:</strong> {order.frontMaterial}<br/>
                                                    <strong>Case Material:</strong> {order.caseMaterial}<br/>
                                                    <strong>Quantity:</strong> {order.quantity}<br/>
                                                </p>
                                            </div>
                                            <div className="order-image">
                                                <img
                                                    src={`${imageURL}${order.serialNumber}.webp`}
                                                    className="card-img-top sr-cstm-img"
                                                    alt={`${order.serialNumber}`}
                                                    // loading="lazy"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="card-footer text-center so-btn-container">
                                        <button
                                            className="btn btn-dark me-2"
                                            onClick={() => handleOrderAgain(tempOrderId)}
                                        >
                                            Order Again
                                        </button>
                                        <button
                                            className="btn btn-dark"
                                            onClick={() => handleDeleteSavedOrder(tempOrderId)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SavedOrdersComponent;
