import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

class PasswordResetService {
    requestPasswordReset(email) {
        return axios.post(`${baseURL}/api/password-reset/request`, null, { params: { email } });
    }

    validateToken(token) {
        return axios.get(`${baseURL}/api/password-reset/validate`, { params: { token } });
    }

    resetPassword(token, newPassword) {
        return axios.post(`${baseURL}/api/password-reset/reset`, null, { params: { token, newPassword } });
    }
}

export default new PasswordResetService();