import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ResetPasswordModal from "./ResetPasswordModal";
import PasswordResetService from "../api/PasswordResetService";

const ResetPasswordPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [token, setToken] = useState(null);
    const [tokenValid, setTokenValid] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenParam = searchParams.get('token');
        if (tokenParam) {
            setToken(tokenParam);
            validateToken(tokenParam);
        } else {
            navigate('/'); // Redirect to the home page or any other appropriate page
        }
    }, [location, navigate]);

    const validateToken = async (token) => {
        try {
            const response = await PasswordResetService.validateToken(token);
            if (response.data.valid) {
                setTokenValid(true);
                setShowResetPassword(true);
            } else {
                setTokenValid(false);
                navigate('/'); // Redirect to the home page or any other appropriate page
            }
        } catch (error) {
            console.error('Token validation failed:', error);
            setTokenValid(false);
            navigate('/'); // Redirect to the home page or any other appropriate page
        }
    };

    const handleResetPasswordClose = () => {
        setShowResetPassword(false);
        navigate('/'); // Redirect to the home page or any other appropriate page
    };

    return (
        <div>
            {showResetPassword && tokenValid && (
                <ResetPasswordModal show={showResetPassword} handleClose={handleResetPasswordClose} token={token} />
            )}
        </div>
    );
};

export default ResetPasswordPage;
