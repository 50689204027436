import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

const ShippingService = {

    getAllAddresses: async (email) => {
        try {
            return await axios.post(`${baseURL}/getAllAddresses`, {
                email: email
            });
        } catch (error) {
            console.error('Error fetching addresses: ', error)
        }
    },

    addAddress: async (shipping) => {
        try {
            return await axios.post(`${baseURL}/addAddress`, {
                address1: shipping.address1,
                address2: shipping.address2,
                apartment: shipping.apartment,
                city: shipping.city,
                state: shipping.state,
                zipcode: shipping.zipcode,
                email: shipping.email
            })
        } catch (error) {
            console.error('Error adding address: ', error)
        }
    }
};

export default ShippingService;
