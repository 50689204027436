import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import OrderPage from "./components/OrderComponent/OrderPage";
import LandingPage from "./components/LandingComponent/LandingPage";
import CartComponent from "./components/CartComponent/CartComponent";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from "./redux/store";
import ProfileComponent from "./components/ProfileComponent/ProfileComponent";
import ShowRoomComponent from "./components/ShowRoomComponent/ShowRoomComponent";
import EditProfileComponent from "./components/ProfileComponent/EditProfileComponent";
import CheckoutComponent from "./components/CheckoutComponent/CheckoutComponent";
import ContactComponent from "./components/ContactComponent/ContactComponent";
import ImageComponent from "./ImageComponent";
import SavedOrdersComponent from "./components/SavedOrdersComponent/SavedOrdersComponent";
import ResetPasswordPage from "./modals/ResetPasswordPage";
import OrderDetailsComponent from "./components/CustomerPortalComponent/OrderDetailsComponent";
import CustomerPortal from "./components/CustomerPortalComponent/CustomerPortal";
import CabinetQCComponent from "./components/CustomerPortalComponent/CabinetQCComponent";
import OrderSummaryComponent from "./components/CustomerPortalComponent/OrderSummaryComponent";
import {ToastContainer} from "react-toastify";
import AuthCheck from "../src/hooks/useAuthCheck";
import OrderMatHardwareComponent from "./components/OrderMatHardwareComponent/OrderMatHardwareComponent";
import PrivacyComponent from "./components/LegalComponents/PrivacyComponent";
import TSComponent from "./components/LegalComponents/TSComponent";
import Footer from "./components/LegalComponents/Footer";
import AboutUsComponent from "./components/AboutUsComponent/AboutUsComponent";
import {HelmetProvider} from "react-helmet-async";
import ToolTipExplainComponent from "./components/ToolTipComponent/ToolTipExplainComponent";
import GlobalSettingsComponent from "./components/GlobalSettingsComponent/GlobalSettingsComponent";
import DrawingTool from "./components/DrawingComponent/DrawingTool";
import OrderManagementComponent from "./components/OrderManagementComponent/OrderManagementComponent";
import AdminRoute from "./components/UploadArticleComponent/AdminRoute";


// const stripePromise = loadStripe('pk_live_51PNcpVHPrftNOJaIOhl0tlU2xcpGczBG2EBxTqp89VIxzcAPYGthqG3CyxUVxdZukljFDMbnrt9FtmrXyiFydcdR00h6K6BoG7');

// const stripePromise = loadStripe('pk_test_51PNcpVHPrftNOJaIGr4jPmF6IzYDzG64q4FHbOGaybQWg9Mn6Y6i20nXkmojJbUALh1mCFCGXKqyJpSPahogWwj500j0VNAFPt');

function App() {
    return (
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <AuthCheck>
                            {/*<Elements stripe={stripePromise}>*/}
                            <Routes>
                                <Route path="/" element={<LandingPage/>}/>
                                <Route path="/order" element={<OrderPage/>}/>
                                <Route path="/cart" element={<CartComponent/>}/>
                                <Route path="/user/profile" element={<ProfileComponent/>}/>
                                <Route path="/showroom" element={<ShowRoomComponent/>}/>
                                <Route path="/user/edit-profile" element={<EditProfileComponent/>}/>
                                <Route path="/checkout" element={<CheckoutComponent/>}/>
                                <Route path="/customer-support/contact" element={<ContactComponent/>}/>
                                <Route path="/user/customer-portal" element={<CustomerPortal/>}/>
                                <Route path="/user/customer-portal/:orderId" element={<OrderDetailsComponent/>}/>
                                <Route path="/api/cabinet/quality-control/:orderId/:positionName"
                                       element={<CabinetQCComponent/>}/>
                                <Route path="/user/customer-portal/:orderId/summary"
                                       element={<OrderSummaryComponent/>}/>
                                <Route path="/user/customer-port/:orderId/materials&hardware"
                                       element={<OrderMatHardwareComponent/>}/>
                                <Route path="/testing" element={<ImageComponent/>}/>
                                <Route path="/user/profile/saved-orders" element={<SavedOrdersComponent/>}/>
                                <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                                <Route path="/the-sealab/legal/privacy" element={<PrivacyComponent/>}/>
                                <Route path="/the-sealab/legal/terms" element={<TSComponent/>}/>
                                <Route path="/the-sealab/about-us" element={<AboutUsComponent/>}/>
                                <Route path="/the-sealab/tool-tip/explain" element={<ToolTipExplainComponent/>}/>
                                <Route path="/cart/global-settings" element={<GlobalSettingsComponent/>}/>
                                {/*<Route path="/drawing" element={<DrawingTool/>}/>*/}
                                <Route path="/drawing" element={<DrawingTool/>}/>
                                {/*<Route path="/internal/order-management" element={<OrderManagementComponent/>}/>*/}
                                <Route
                                    path="/internal/order-management"
                                    element={<AdminRoute element={<OrderManagementComponent/>}/>}
                                />
                            </Routes>
                            {/*</Elements>*/}
                            <ToastContainer position="top-center" autoClose={2000}
                                            hideProgressBar={true}></ToastContainer>
                            {/*<TokenChecker/>*/}
                        </AuthCheck>
                        <Footer/>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </HelmetProvider>
    );
}

export default App;
