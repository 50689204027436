// import React, {useEffect, useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import {useNavigate} from 'react-router-dom';
// import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
// import NavbarComponent from '../NavBarComponents/NavbarComponent';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import DataService from '../../api/DataService';
// import {selectGlobalSettings, updateGlobalSettings} from '../../features/globalSettings/globalSettingsSlice';
// import './GlobalSettingsStyles.css';
// import {FaInfoCircle, FaSave} from 'react-icons/fa';
// import axios from "axios";
//
// function GlobalSettingsComponent() {
//     const userLoggedIn = UserLoggedIn();
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const globalSettings = useSelector(selectGlobalSettings);
//
//     const [materials, setMaterials] = useState([]);
//     const [edgebandingOptions, setEdgebandingOptions] = useState([]);
//     const [jointOptions, setJointOptions] = useState([]);
//     const [hingeOptions, setHingeOptions] = useState([]);
//     const [backPanelOptions, setBackPanelOptions] = useState([]);
//     const [drawerOptions, setDrawerOptions] = useState([]);
//     const [configOptions, setConfigOptions] = useState([]);
//     const baseURL = process.env.REACT_APP_BASEURL;
//
//
//     useEffect(() => {
//         window.scrollTo({top: 0, behavior: "instant"});
//         fetchMaterials();
//         fetchEdgebandingOptions();
//         fetchConfigOptions();
//     }, []);
//
//     const fetchMaterials = async () => {
//         try {
//             const response = await DataService.getAllMaterials();
//             const sortedMaterials = response.sort((a, b) => {
//                 if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
//                     return -1;
//                 } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
//                     return 1;
//                 }
//                 return a.description.localeCompare(b.description);
//             });
//             setMaterials(sortedMaterials);
//         } catch (error) {
//             console.error('Error fetching materials:', error);
//         }
//     };
//
//     const fetchEdgebandingOptions = async () => {
//         try {
//             const response = await DataService.getAllEdgebanding();
//             setEdgebandingOptions(response);
//         } catch (error) {
//             console.error('Error fetching edgebanding options:', error);
//         }
//     };
//
//     const fetchConfigOptions = () => {
//         axios.get(`${baseURL}/api/cabinet-features/getAllConfigOptions`)
//             .then((res) => {
//                 setConfigOptions(res.data);
//                 setJointOptions(res.data
//                     .filter(j => j.setting === 'Joint Method' && j.isVisible === '1')
//                     .map(j => j.description));
//                 setBackPanelOptions(res.data
//                     .filter(b => b.setting === 'Back Panel Method' && b.isVisible === '1')
//                     .map(b => b.description));
//                 setHingeOptions(res.data
//                     .filter(h => h.setting === 'Hinge Plate' && h.isVisible === '1')
//                     .map(h => h.description));
//                 setDrawerOptions(res.data
//                     .filter(d => d.setting === 'Drawer Type' && d.isVisible === '1')
//                     .map(d => d.description));
//             })
//             .catch(error => console.error('Error fetching configuration options:', error));
//     };
//
//     const handleInputChange = (field, value) => {
//         dispatch(updateGlobalSettings({[field]: value}));
//     };
//
//     const renderDropdown = (label, fieldName, options = [], defaultValue = "") => (
//         <div className="global-settings__form-group">
//             <label htmlFor={fieldName} className="global-settings__label">
//                 {label}
//                 <FaInfoCircle
//                     className="global-settings__info-icon"
//                     onClick={() => handleToolTipClick(fieldName)}
//                 />
//             </label>
//             <select
//                 id={fieldName}
//                 className="global-settings__select"
//                 value={globalSettings[fieldName] || defaultValue}
//                 onChange={(e) => handleInputChange(fieldName, e.target.value)}
//             >
//                 <option value="">{defaultValue}</option>
//                 {options.map((option, index) => (
//                     <option key={index} value={option}>
//                         {option}
//                     </option>
//                 ))}
//             </select>
//         </div>
//     );
//
//     const handleToolTipClick = (field) => {
//         navigate("/the-sealab/tool-tip/explain", {state: {field: field}});
//     }
//
//     const handleSaveSettings = () => {
//         navigate(-1);
//     }
//
//     return (
//         <>
//             {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//             <div className="global-settings-page min-vh-100">
//                 <div className="global-settings__container">
//                     <h1 className="global-settings__title">Global Settings</h1>
//                     <div className="global-settings__content">
//                         <section className="global-settings__section">
//                             <h2 className="global-settings__section-title">Materials</h2>
//                             {renderDropdown('Case Material', 'caseMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
//                             {renderDropdown('Front Material', 'frontMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
//                             {renderDropdown('Back Panel Material', 'backPanelMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
//                         </section>
//
//                         <section className="global-settings__section">
//                             <h2 className="global-settings__section-title">Joint Methods</h2>
//                             {renderDropdown('Joint Method', 'jointMethod', jointOptions)}
//                             {renderDropdown('Back Panel Method', 'backPanel', backPanelOptions)}
//                         </section>
//
//                         <section className="global-settings__section">
//                             <h2 className="global-settings__section-title">Edgebanding</h2>
//                             {renderDropdown('Case Edge', 'caseEdge', edgebandingOptions.map(o => o.englishDescription))}
//                             {renderDropdown('Front Edge', 'frontEdge', edgebandingOptions.map(o => o.englishDescription))}
//                         </section>
//
//                         <section className="global-settings__section">
//                             <h2 className="global-settings__section-title">Door Settings</h2>
//                             {renderDropdown('Hinge Options', 'hingePlate', hingeOptions)}
//                         </section>
//
//                         <section className="global-settings__section">
//                             <h2 className="global-settings__section-title">Drawer Settings</h2>
//                             {renderDropdown('Drawer Options', 'drawerType', drawerOptions)}
//                         </section>
//
//                         <button className="global-settings__save-button" onClick={handleSaveSettings}>
//                             <FaSave/> Save and Return
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }
//
// export default GlobalSettingsComponent;


import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import DataService from '../../api/DataService';
import {selectGlobalSettings, updateGlobalSettings} from '../../features/globalSettings/globalSettingsSlice';
import './GlobalSettingsStyles.css';
import {FaInfoCircle, FaSave} from 'react-icons/fa';
import axios from "axios";

function GlobalSettingsComponent() {
    const userLoggedIn = UserLoggedIn();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const globalSettings = useSelector(selectGlobalSettings);

    const [materials, setMaterials] = useState([]);
    const [edgebandingOptions, setEdgebandingOptions] = useState([]);
    const [jointOptions, setJointOptions] = useState([]);
    const [hingeOptions, setHingeOptions] = useState([]);
    const [backPanelOptions, setBackPanelOptions] = useState([]);
    const [drawerOptions, setDrawerOptions] = useState([]);
    const [configOptions, setConfigOptions] = useState([]);
    const baseURL = process.env.REACT_APP_BASEURL;

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"});
        fetchMaterials();
        fetchEdgebandingOptions();
        fetchConfigOptions();
    }, []);

    const fetchMaterials = async () => {
        try {
            const response = await DataService.getAllMaterials();
            const sortedMaterials = response.sort((a, b) => {
                if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                    return -1;
                } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                    return 1;
                }
                return a.description.localeCompare(b.description);
            });
            setMaterials(sortedMaterials);
        } catch (error) {
            console.error('Error fetching materials:', error);
        }
    };

    const fetchEdgebandingOptions = async () => {
        try {
            const response = await DataService.getAllEdgebanding();
            setEdgebandingOptions(response);
        } catch (error) {
            console.error('Error fetching edgebanding options:', error);
        }
    };

    const fetchConfigOptions = () => {
        axios.get(`${baseURL}/api/cabinet-features/getAllConfigOptions`)
            .then((res) => {
                setConfigOptions(res.data);
                setJointOptions(res.data
                    .filter(j => j.setting === 'Joint Method' && j.isVisible === '1')
                    .map(j => j.description));
                setBackPanelOptions(res.data
                    .filter(b => b.setting === 'Back Panel Method' && b.isVisible === '1')
                    .map(b => b.description));
                setHingeOptions(res.data
                    .filter(h => h.setting === 'Hinge Plate' && h.isVisible === '1')
                    .map(h => h.description));
                setDrawerOptions(res.data
                    .filter(d => d.setting === 'Drawer Type' && d.isVisible === '1')
                    .map(d => d.description));
            })
            .catch(error => console.error('Error fetching configuration options:', error));
    };

    const handleInputChange = (field, value) => {
        dispatch(updateGlobalSettings({[field]: value}));
    };

    const renderDropdown = (label, fieldName, options = []) => (
        <div className="global-settings__form-group">
            <label htmlFor={fieldName} className="global-settings__label">
                {label}
                <FaInfoCircle
                    className="global-settings__info-icon"
                    onClick={() => handleToolTipClick(fieldName)}
                />
            </label>
            <select
                id={fieldName}
                className="global-settings__select"
                value={globalSettings[fieldName] || options[0] || ''}
                onChange={(e) => handleInputChange(fieldName, e.target.value)}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );

    const handleToolTipClick = (field) => {
        navigate("/the-sealab/tool-tip/explain", {state: {field: field}});
    };

    const handleSaveSettings = () => {
        navigate(-1);
    };

    return (
        <>
            {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
            <div className="global-settings-page min-vh-100">
                <div className="global-settings__container">
                    <h1 className="global-settings__title">Global Settings</h1>
                    <div className="global-settings__content">
                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Materials</h2>
                            {renderDropdown('Case Material', 'caseMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
                            {renderDropdown('Front Material', 'frontMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
                            {renderDropdown('Back Panel Material', 'backPanelMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
                        </section>

                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Joint Methods</h2>
                            {renderDropdown('Joint Method', 'jointMethod', jointOptions)}
                            {renderDropdown('Back Panel Method', 'backPanel', backPanelOptions)}
                        </section>

                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Edgebanding</h2>
                            {renderDropdown('Case Edge', 'caseEdge', edgebandingOptions.map(o => o.englishDescription))}
                            {renderDropdown('Front Edge', 'frontEdge', edgebandingOptions.map(o => o.englishDescription))}
                        </section>

                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Door Settings</h2>
                            {renderDropdown('Hinge Options', 'hingePlate', hingeOptions)}
                        </section>

                        <section className="global-settings__section">
                            <h2 className="global-settings__section-title">Drawer Settings</h2>
                            {renderDropdown('Drawer Options', 'drawerType', drawerOptions)}
                        </section>

                        <button className="global-settings__save-button" onClick={handleSaveSettings}>
                            <FaSave/> Save and Return
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GlobalSettingsComponent;
