import React, {useEffect} from 'react';
import './PrivacyStyles.css';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";

const PrivacyComponent = () => {
    const userLoggedIn = UserLoggedIn();

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"})
    })

    return (
        <>
            {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
            <div className="container mt-5 min-vh-100">

                <div className="privacy-policy">
                    <h1 className="text-center">Privacy Policy</h1>
                    <p className="text-center">Effective Date: 6-21-2024</p>
                    <h2>1. Introduction</h2>
                    <p>
                        The Sealab ("we," "us," "our") respects your privacy and is committed to protecting it through
                        our compliance with this policy. This Privacy Policy describes the types of information we may
                        collect from you or that you may provide when you visit our website www.thesealab.com
                        ("Website") and our practices for collecting, using, maintaining, protecting, and disclosing
                        that information.
                    </p>
                    <h2>2. Information We Collect</h2>
                    <h3>Personal Information:</h3>
                    <p>
                        <strong>Customer Information:</strong> Name, address, saved shopping carts, current and past
                        orders including items purchased and amount paid.
                        <br/>
                        <strong>Order Specific Information:</strong> Project address, client info, and shipping
                        information (optional).
                    </p>
                    <h3>Non-Personal Information:</h3>
                    <p>
                        <strong>Order status (processed or not).</strong>
                        <br/>
                        Note: We do not collect or store any billing address or payment information. All payment-related
                        data is handled directly by Stripe.
                    </p>
                    <h2>3. How We Use Your Information</h2>
                    <p>
                        <strong>Order Fulfillment:</strong> To process and fulfill your orders, including managing
                        payments through Stripe.
                        <br/>
                        <strong>Customer Service:</strong> To provide customer support and respond to inquiries.
                        <br/>
                        <strong>Marketing:</strong> To send marketing communications about our products and services
                        (opt-out available). We do not use client or project information for marketing.
                        <br/>
                        <strong>Improvement:</strong> To improve our website and services.
                    </p>
                    <h2>4. Information Sharing and Disclosure</h2>
                    <p>
                        <strong>Service Providers:</strong> We share order-specific information, including project and
                        customer information, with our fulfillment partners. This information is used solely for
                        labeling and parts tracking. No payment information is shared.
                        <br/>
                        <strong>Legal Requirements:</strong> We may disclose your information to comply with legal
                        obligations or to protect our rights.
                    </p>
                    <h2>5. Data Retention</h2>
                    <p>
                        We retain personal information for as long as necessary to fulfill the purposes for which it was
                        collected, including any legal or reporting requirements. You may request the deletion of your
                        information at any time.
                    </p>
                    <h2>6. Your Rights</h2>
                    <p>
                        <strong>Access and Correction:</strong> You may access and update your personal information by
                        contacting us.
                        <br/>
                        <strong>Deletion:</strong> You may request the deletion of your personal information.
                        <br/>
                        <strong>Opt-Out:</strong> You can opt-out of marketing communications at any time.
                    </p>
                    <h2>7. Data Security</h2>
                    <p>
                        We implement appropriate security measures to protect your personal information from
                        unauthorized access, use, or disclosure. However, no internet transmission is completely secure,
                        and we cannot guarantee its absolute security.
                    </p>
                    <h2>8. Cookies</h2>
                    <p>
                        We do not use cookies. Instead, we use Redux, a technology that helps us manage the state of our
                        application and keeps track of your interactions with our site without storing data in your
                        browser.
                    </p>
                    <h2>9. Hosted Documents and Drawing Packages</h2>
                    <p>
                        <strong>Drawing Packages:</strong> We offer comprehensive drawing packages for your custom
                        furniture orders. These drawings are generated from the same data that drives our production
                        machinery, ensuring accuracy.
                        <br/>
                        <strong>Access to Hosted Documents:</strong> Customers have access to hosted documents related
                        to production data, drawings, lists, and other relevant information. These documents can be
                        accessed, downloaded, and used at any time for your convenience.
                        <br/>
                        <strong>Accuracy and Responsibility:</strong> While we strive to ensure the accuracy of the
                        drawings, they are to be used under advisement. The ultimate responsibility for ensuring the
                        proper fit and finish of the product lies with the customer.
                        <br/>
                        <strong>Public Access:</strong> The drawings will be made available to anyone with the public
                        link to the data. Public access versions of the drawings will not contain any customer or client
                        information—only the order number and product information.
                    </p>
                    <h2>10. Children's Privacy (COPPA)</h2>
                    <p>
                        We do not knowingly collect personal information from children under the age of 13. If we learn
                        that we have collected personal information from a child under 13 without verifiable parental
                        consent, we will delete that information as quickly as possible. If you believe that we might
                        have any information from or about a child under 13, please contact us at info@TheSealab.com.
                    </p>
                    <h2>11. International Users</h2>
                    <p>
                        If you are accessing our services from outside the United States, please be aware that your
                        information may be transferred to, stored, and processed in the United States where our servers
                        are located and our central database is operated. By using our services, you consent to the
                        transfer of your information to the United States.
                        <br/>
                        We comply with the General Data Protection Regulation (GDPR) for users in the European Union.
                        This includes:
                        <br/>
                        <strong>The right to access your data.</strong>
                        <br/>
                        <strong>The right to correct any inaccuracies.</strong>
                        <br/>
                        <strong>The right to request the deletion of your data.</strong>
                        <br/>
                        <strong>The right to data portability.</strong>
                        <br/>
                        <strong>The right to restrict processing of your data.</strong>
                        <br/>
                        If you have any questions about our international privacy practices, please contact us at
                        info@TheSealab.com.
                        <br/>
                        For users in Canada, we comply with the Personal Information Protection and Electronic Documents
                        Act (PIPEDA). This includes obtaining your consent for the collection, use, and disclosure of
                        your personal information, and providing you with the ability to access and correct your
                        information. If you have any questions about our Canadian privacy practices, please contact us
                        at info@TheSealab.com.
                    </p>
                    <h2>12. Changes to Our Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by
                        posting the new policy on our website. Your continued use of our website after any changes
                        indicates your acceptance of the updated Privacy Policy.
                    </p>
                    <h2>13. Contact Information</h2>
                    <p>
                        If you have any questions or concerns about this Privacy Policy, please contact us at:
                        <br/>
                        Sealab Productions (The Sealab)
                        <br/>
                        63 Flushing Ave
                        <br/>
                        Building 3, Suite 1108
                        <br/>
                        Brooklyn, NY 11205
                        <br/>
                        info@thesealab.com
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrivacyComponent;
