// DataService.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

const ShowRoomService = {
    getAllCabinets: async () => {
        try {
            const response = await axios.get(`${baseURL}/getAllCabinets`);
            return response.data;
        } catch (error) {
            console.error('Error fetching materials:', error);
            return [];
        }
    },
};

export default ShowRoomService;
