import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import './ToolTipExplainStyles.css';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import ToolTipService from "../../api/ToolTipService";
import ToolTipImageCarousel from '../ImageCarouselComponent/ToolTipImageCarousel'; // Import the new carousel

const ToolTipExplainComponent = () => {
    const location = useLocation();
    const [field, setField] = useState(null);
    const [fileContent, setFileContent] = useState('');
    const userLoggedIn = UserLoggedIn();

    // Function to dynamically format camelCase to spaced words with proper capitalization
    const formatField = (input) => {
        if (input === 'positionName')
            return 'Item Name'
        if (input === 'numOfShelves')
            return 'Number of Adjustable Shelves'
        if (input === 'topDrwrHeight')
            return 'Top Drawer Height'

        return input
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert a space before capital letters
            .toLowerCase() // Convert the entire string to lowercase
            .split(' ') // Split by spaces
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');
    };

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"})
        console.log('asd')
        if (location.state && location.state.field) {
            setField(location.state.field);
        }
    }, [location]);

    useEffect(() => {
        if (field !== null && field !== 'undefined') {
            ToolTipService.getToolTipExplanation(field)
                .then(response => {
                    setFileContent(response.data);  // Assume this is HTML content
                })
                .catch(error => {
                    console.error('Error fetching file content:', error);
                    setFileContent('No explanation available.');
                });
        }
    }, [field]);

    return (
        <>
            {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}

            <div className="container-fluid min-vh-100">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="tooltip-explain-container">
                            {fileContent ? (
                                <div className="tooltip-explain-content">
                                    {/*<h2 className="tooltip-explain-title">{formatField(field)}</h2>*/}
                                    <div className="tooltip-explain-text"
                                         dangerouslySetInnerHTML={{__html: fileContent}}>
                                    </div>
                                </div>
                            ) : (
                                <p className="tooltip-no-data">No field data available</p>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                        <ToolTipImageCarousel field={field}/> {/* Pass raw field value to carousel */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ToolTipExplainComponent;
