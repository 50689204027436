import {useSelector} from 'react-redux';

export const UserLoggedIn = () => {
    const user = useSelector(state => state.user.user) || {};
    // For additional debugging, you can log these values, but it's generally not recommended to have logs in production code
    if (user.email === null)
        return false

    return Boolean(Object.keys(user).length > 0);
};
