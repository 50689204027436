import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import NavbarLoggedInComponent from "../NavBarComponents/NavbarLoggedInComponent";
import NavbarComponent from "../NavBarComponents/NavbarComponent";
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import {useSelector} from "react-redux";
import axios from "axios";
import './CabinetQCStyles.css';
import DocImageCarousel from "../ImageCarouselComponent/DocImageCarousel";

const CabinetQCComponent = () => {
    const location = useLocation();
    const token = useSelector(state => state.user.accessToken);
    const userLoggedIn = UserLoggedIn();
    const {article: initialArticle, order} = location.state || {};
    const [article, setArticle] = useState(initialArticle)
    const {orderId, positionName} = useParams();
    const [lineItem, setLineItem] = useState([]);
    const [caseMaterial, setCaseMaterial] = useState('');
    const [frontMaterial, setFrontMaterial] = useState('');
    const [caseSqft, setCaseSqft] = useState(null);
    const [frontSqft, setFrontSqft] = useState(null);
    const [ebPrfName, setEbPrfName] = useState(null);
    const [ebSqft, setEbSqft] = useState(null);
    const [hardware, setHardware] = useState({});
    const [drawers, setDrawers] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const baseURL = process.env.REACT_APP_BASEURL;
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;
    const explodedThmbNail = process.env.REACT_APP_THMBNAIL
    const finalOrderId = order?.orderId || orderId;
    const drawingsURL = process.env.REACT_APP_THMBNAIL + `${finalOrderId} /Article_Report`

    useEffect(() => {
        const fetchOrderSummary = (url, body) => {
            return axios.post(url, body, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data;
                        console.log(data.drawers)
                        setCaseSqft(data.caseSqft);
                        setFrontSqft(data.frontSqft);
                        setEbPrfName(data.ebPrfName);
                        setEbSqft(data.edgeBanding);
                        setHardware(data.hardware || {});
                        setDrawers(data.drawers);
                        setArticle(data.article)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        setErrorMessage("Sorry. Your order is still being processed. You will receive an email when your order has been processed.");
                    } else {
                        console.error('Error fetching order summary:', error);
                    }
                });
        };

        let isMounted = true;
        const controller = new AbortController();

        const fetchData = async () => {
            if (article) {
                const body = {
                    caseMaterial: article.caseMaterial,
                    frontMaterial: article.frontMaterial
                };

                if (order) {
                    await fetchOrderSummary(`${baseURL}/api/orders/${order?.orderId}/summary/${article.positionName}`, body);
                }
            } else if (orderId && positionName) {
                try {
                    const response = await axios.get(`${baseURL}/api/orders/${orderId}/summary/${positionName}/getMaterials`, {
                        signal: controller.signal
                    });
                    if (response.status === 200 && isMounted) {
                        const {caseMaterial, frontMaterial} = response.data;
                        setCaseMaterial(caseMaterial);
                        setFrontMaterial(frontMaterial);

                        const body = {
                            caseMaterial: caseMaterial,
                            frontMaterial: frontMaterial
                        };
                        await fetchOrderSummary(`${baseURL}/api/orders/${orderId}/summary/${positionName}`, body);
                    }
                } catch (error) {
                    if (error.name !== 'AbortError' && isMounted) {
                        console.error('Error fetching materials:', error);
                    }
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
            controller.abort();
        };
        // }, [article, order, orderId, positionName, token, baseURL, finalOrderId]);
    }, []);

    const renderMaterials = () => (
        <>
            {caseSqft && <tr>
                <td>Case Material</td>
                <td>{article?.caseMaterial || caseMaterial}</td>
                <td>{caseSqft} sqft</td>
            </tr>}
            {frontSqft && <tr>
                <td>Front Material</td>
                <td>{article?.frontMaterial || frontMaterial}</td>
                <td>{frontSqft} sqft</td>
            </tr>}

            {ebSqft && <tr>
                <td>Edge Band</td>
                <td>{ebPrfName}</td>
                <td>{ebSqft} ft</td>
            </tr>}
            {article?.swEdgeSqft && <tr>
                <td>Solid Wood Edge</td>
                <td>{article?.swEdge}</td>
                <td>{article?.swEdgeSqft}</td>
            </tr>}
            {article?.mEdgeSqft && <tr>
                <td>Miter Edge</td>
                <td>{article?.miterEdge}</td>
                <td>{article?.mEdgeSqft} ft</td>
            </tr>}
        </>
    );

    const renderHardware = () => (
        hardware.map((item, index) => (
            <tr key={index}>
                <td>{item.hardware}</td>
                <td>{item.mfgNum}</td>
                <td>{item.quantity}</td>
            </tr>
        ))
    );

    const renderDrawers = () => {
        const drawerMap = {};

        drawers.forEach(drawer => {
            const key = `${drawer.cpId}-${drawer.mfgNum}-${drawer.width}-${drawer.depth}-${drawer.height}`;
            if (!drawerMap[key]) {
                drawerMap[key] = {...drawer, quantity: 0};
            }
            drawerMap[key].quantity += 1;
        });

        return Object.values(drawerMap).map((drawer, index) => (
            <tr key={index}>
                <td>{drawer.cpId}</td>
                <td>{drawer.mfgNum}</td>
                <td>{drawer.width} in</td>
                <td>{drawer.depth} in</td>
                <td>{drawer.height} in</td>
                <td>{drawer.quantity}</td>
            </tr>
        ));
    };

    return (
        <div>
            {userLoggedIn ? (
                <NavbarLoggedInComponent/>
            ) : (
                <NavbarComponent/>
            )}
            <div className="container my-4 min-vh-100">
                {errorMessage ? (
                    <div className="d-flex justify-content-center" role="alert">
                        <span><strong>{errorMessage}</strong></span>
                    </div>
                ) : (
                    <>
                        <div className="row mb-4" id="image-dimensions-container">
                            <div className="col-md-4">
                                {article && (
                                    <>
                                        <p className="pos-txt">{article.positionName}</p>
                                        <div className="doc__image-wrapper">
                                            <DocImageCarousel
                                                orderId={orderId}
                                                positionName={article.positionName}
                                            />

                                            {/*<img src={`${thumbNail}${orderId}/${article.positionName}.png`}*/}
                                            {/*     alt={`Article ${article.positionName}`} className="article-thumbnail"/>*/}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="col-md-4">
                                <div className="card cbt-qc-card" id="dimensions-container">
                                    <div className="dim-card d-flex flex-column" id="dimensions-card">

                                        {article?.width !== 0 && (<div className="d-flex justify-content-between">
                                                <p className="card-text dimensions-item"><strong>Width:</strong></p>
                                                <p className="card-text dimensions-item">{article?.width} in</p>
                                            </div>
                                        )}

                                        {article?.depth !== 0 && (
                                            <div className="d-flex justify-content-between">
                                                <p className="card-text dimensions-item"><strong>Depth:</strong></p>
                                                <p className="card-text dimensions-item">{article?.depth} in</p>
                                            </div>
                                        )}

                                        {article?.height !== 0 && (
                                            <div className="d-flex justify-content-between">
                                                <p className="card-text dimensions-item"><strong>Height:</strong></p>
                                                <p className="card-text dimensions-item">{article?.height} in</p>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <h3 className="mt-4 summary-title">Article Summary</h3>

                        {(caseSqft || frontSqft || ebSqft || article?.swEdgeSqft || article?.mEdgeSqft) && (
                            <div className="row production">
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <div className="vertical-text" id="parts-vert-text">
                                        <span>PARTS PRODUCTION</span>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>MFG #</th>
                                                    <th>QNTY</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderMaterials()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {Object.keys(hardware).length > 0 && (
                            <div className="row mt-4 hardware">
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <div className="vertical-text" id="hardware-vert-text">
                                        <span>HARDWARE</span>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>MFG #</th>
                                                    <th>QNTY</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {hardware && renderHardware()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {drawers.length > 0 && (
                            <div className="row mt-4 drawers">
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <div className="vertical-text" id="drawers-vert-text">
                                        <span>DRAWERS</span>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>MFG #</th>
                                                    <th>Width</th>
                                                    <th>Depth</th>
                                                    <th>Height</th>
                                                    <th>QNTY</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderDrawers()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CabinetQCComponent;
