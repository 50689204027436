import React, {startTransition, Suspense, useCallback, useEffect, useMemo, useState} from 'react';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import './ShowRoomStyles.css';
import ShowRoomService from "../../api/ShowRoomService";
import {useDispatch} from "react-redux";
import {addToCart} from "../../features/cart/cartSlice";
import {useNavigate} from "react-router-dom";
import {nanoid} from "@reduxjs/toolkit";
import {Helmet} from 'react-helmet-async'
// import ImageCarousel from "../ImageCarouselComponent/ImageCarousel";
import NavbarLoggedInComponent from "../NavBarComponents/NavbarLoggedInComponent";
import NavbarComponent from "../NavBarComponents/NavbarComponent";


const ImageCarousel = React.lazy(() => import('../ImageCarouselComponent/ImageCarousel'))
// const NavbarLoggedInComponent = React.lazy(() => import('../NavBarComponents/NavbarLoggedInComponent'))
// const NavbarComponent = React.lazy(() => import('../NavBarComponents/NavbarComponent'))

const ShowRoomComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const dispatch = useDispatch();
    const [allCabinets, setAllCabinets] = useState([]);
    const [randomizedCabinets, setRandomizedCabinets] = useState([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [quantities, setQuantities] = useState({});
    const [cardWidth, setCardWidth] = useState(280);
    const [expandedCategories, setExpandedCategories] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // New state for search query
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const cardsPerPage = 28;

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            setCardWidth(windowWidth <= 576 ? windowWidth - 70 : 350);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        ShowRoomService.getAllCabinets()
            .then((res) => {
                const cabinets = res.map(cabinet => ({
                    ...cabinet,
                    id: nanoid()
                }));
                setAllCabinets(cabinets);
                setRandomizedCabinets(shuffleArray([...cabinets]));
                setTotalPages(Math.ceil(cabinets.length / cardsPerPage));
                setDataLoaded(true);
            })
            .catch((e) => {
                console.error(e);
            });
    }, []);

    const handleFilterSelection = useCallback((filter) => {
        startTransition(() => {
            setSelectedFilters(prevFilters =>
                prevFilters.includes(filter)
                    ? prevFilters.filter(item => item !== filter)
                    : [...prevFilters, filter]
            );
            setCurrentPage(1);
            window.scrollTo({top: 0, behavior: "instant"});
        })


    }, []);

    const isFilterSelected = useCallback((filter) => selectedFilters.includes(filter), [selectedFilters]);

    const handleAddToCart = useCallback((cabinet) => {
        const qty = quantities[cabinet.id] || 1;
        dispatch(addToCart({...cabinet, id: nanoid(), quantity: parseInt(qty, 10)}));
    }, [dispatch, quantities]);

    const handleQuantityChange = useCallback((id, value) => {
        setQuantities(prev => ({...prev, [id]: value}));
    }, []);

    const handleConfigure = useCallback(async (item) => {
        const qty = quantities[item.id] || 1;
        const uniqueId = nanoid();
        item = {...item, quantity: qty, id: uniqueId}
        navigate('/order', {state: {items: [item], fromConfigureButton: true, uniqueId}});
    }, [navigate, quantities]);

    const handleCategoryClick = useCallback((category) => {
        setExpandedCategories(prevCategories =>
            prevCategories.includes(category)
                ? prevCategories.filter(item => item !== category)
                : [...prevCategories, category]
        );
    }, []);

    const isCategoryExpanded = useCallback((category) => expandedCategories.includes(category), [expandedCategories]);

    const hasCabinetsWithFilterTag = useCallback((filterTag) => {
        return allCabinets.some(cabinet => {
            const filterTags = cabinet.filterTags.split(',').map(tag => tag.trim());
            return filterTags.includes(filterTag);
        });
    }, [allCabinets]);

    const renderFilterItem = useCallback((filterTag, displayName) => {
        if (hasCabinetsWithFilterTag(filterTag)) {
            return (
                <div
                    key={filterTag}
                    className={`sr-dropdown-item ${isFilterSelected(filterTag) ? 'selected' : ''}`}
                    onClick={() => handleFilterSelection(filterTag)}
                >
                    {displayName}
                </div>
            );
        }
        return null;
    }, [hasCabinetsWithFilterTag, isFilterSelected, handleFilterSelection]);

    const renderSubcategory = useCallback((category, subcategories) => {
        const hasChildrenWithFilterTags = subcategories.some(subcategory =>
            subcategory.tags.some(tag => hasCabinetsWithFilterTag(tag))
        );

        if (hasChildrenWithFilterTags) {
            return (
                <div key={category} className="category-division">
                    <button
                        className="btn btn-dark sr-dropdown-toggle w-100 text-start"
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </button>
                    {isCategoryExpanded(category) && (
                        <div className="sr-dropdown-subcontent">
                            {subcategories.map(subcategory =>
                                subcategory.tags.map(tag => renderFilterItem(tag, subcategory.displayName))
                            )}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    }, [hasCabinetsWithFilterTag, handleCategoryClick, isCategoryExpanded, renderFilterItem]);

    const renderCategory = useCallback((category, subcategories) => {
        const hasChildrenWithFilterTags = subcategories.some(subcategory =>
            subcategory.tags
                ? subcategory.tags.some(tag => hasCabinetsWithFilterTag(tag))
                : subcategory.subcategories && subcategory.subcategories.some(sub =>
                sub.tags.some(tag => hasCabinetsWithFilterTag(tag))
            )
        );

        if (hasChildrenWithFilterTags) {
            return (
                <div key={category} className="filter-category">
                    <button
                        className="btn btn-dark sr-dropdown-toggle w-100 text-start"
                        onClick={() => handleCategoryClick(category)}
                    >
                        {category}
                    </button>
                    {isCategoryExpanded(category) && (
                        <div className="sr-dropdown-content">
                            {subcategories.map(subcategory =>
                                subcategory.tags
                                    ? renderFilterItem(subcategory.tags[0], subcategory.displayName)
                                    : renderSubcategory(subcategory.category, subcategory.subcategories)
                            )}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    }, [hasCabinetsWithFilterTag, handleCategoryClick, isCategoryExpanded, renderFilterItem, renderSubcategory]);

    const memoizedCategories = useMemo(() => [
        {
            category: 'Cabinet Type and Function', subcategories: [
                {category: 'Base', displayName: 'Base', tags: ['Base']},
                {category: 'Upper', displayName: 'Upper', tags: ['Upper']},
                {category: 'FullHeight', displayName: 'Full Height', tags: ['FullHeight']},
                {category: 'Vanity', displayName: 'Vanity', tags: ['Vanity']},
                {category: 'Medicine', displayName: 'Medicine', tags: ['Medicine']},
                {category: 'Closet', displayName: 'Closet', tags: ['Closet']},
                {category: 'Refrigerator', displayName: 'Refrigerator', tags: ['Refrigerator']},
                {category: 'SinkBase', displayName: 'Sink Base', tags: ['SinkBase']},
                {category: 'Dishwasher_Panel', displayName: 'Dishwasher Panel', tags: ['Dishwasher_Panel']},
                {category: 'ApplianceGarage', displayName: 'Appliance Garage', tags: ['ApplianceGarage']},
                {category: 'Oven', displayName: 'Oven', tags: ['Oven']},
                {category: 'Microwave', displayName: 'Microwave', tags: ['Microwave']},
                {category: 'Coffee_System', displayName: 'Coffee System', tags: ['Coffee_System']},
                {category: 'TV', displayName: 'TV', tags: ['TV']},
                {category: 'Washing_Machine', displayName: 'Washing Machine', tags: ['Washing_Machine']},
                {category: 'Dryer', displayName: 'Dryer', tags: ['Dryer']},
                {category: 'Lazy_Susan', displayName: 'Lazy Susan', tags: ['Lazy_Susan']},
                {category: 'TambourCorner', displayName: 'Tambour Corner', tags: ['TambourCorner']},
                {category: 'PantryCorner', displayName: 'Pantry Corner', tags: ['PantryCorner']},
                {category: 'Blind_Corner_Pullout', displayName: 'Blind Corner Pullout', tags: ['Blind_Corner_Pullout']},
                {category: 'Finished_Panel', displayName: 'Finished Panel', tags: ['Finished_Panel']},
                {category: 'Surround_Panels', displayName: 'Surround Panels', tags: ['Surround_Panels']},
                {category: 'Filler', displayName: 'Filler', tags: ['Filler']},
                {category: 'Toe_Kick', displayName: 'Toe Kick', tags: ['Toe_Kick']},
                {category: 'Loose_Parts', displayName: 'Loose Parts', tags: ['Loose_Parts']},
                {category: 'Countertop', displayName: 'Countertop', tags: ['Countertop']},
                {category: 'Plinth_unfinished', displayName: 'Plinth (unfinished)', tags: ['Plinth_unfinished']},
                {category: 'Open_Shelving', displayName: 'Open Shelving', tags: ['Open_Shelving']},
                {category: 'Bookcase', displayName: 'Bookcase', tags: ['Bookcase']},
                {category: 'Desk', displayName: 'Desk', tags: ['Desk']},
                {category: 'Vitrine', displayName: 'Vitrine', tags: ['Vitrine']},
                {category: 'Banquette', displayName: 'Banquette', tags: ['Banquette']},
                {category: 'Bench', displayName: 'Bench', tags: ['Bench']},
                {category: 'Credenza', displayName: 'Credenza', tags: ['Credenza']},
                {category: 'WineRack', displayName: 'Wine Rack', tags: ['WineRack']},
                {category: 'Bed', displayName: 'Bed', tags: ['Bed']}
            ]
        },
        {
            category: 'Cabinet Features', subcategories: [
                {
                    category: 'Door Style', subcategories: [
                        {category: 'Shaker', displayName: 'Shaker', tags: ['Shaker']},
                        {category: 'Flat_Panel', displayName: 'Flat Panel', tags: ['Flat_Panel']},
                        {category: 'Raised_Panel', displayName: 'Raised Panel', tags: ['Raised_Panel']},
                        {category: 'Glass_Front', displayName: 'Glass Front', tags: ['Glass_Front']},
                        {category: 'Louvered', displayName: 'Louvered', tags: ['Louvered']},
                        {category: 'Sliding_Door', displayName: 'Sliding Door', tags: ['Sliding_Door']},
                        {category: 'TambourVertical', displayName: 'Tambour Vertical', tags: ['TambourVertical']},
                        {category: 'TambourHorizontal', displayName: 'Tambour Horizontal', tags: ['TambourHorizontal']},
                        {category: 'Flap_Door', displayName: 'Flap Door', tags: ['Flap_Door']},
                        {category: 'JibDoor', displayName: 'Jib Door', tags: ['JibDoor']},
                        {category: 'Bi-Fold_Door', displayName: 'Bi-Fold Door', tags: ['Bi-Fold_Door']}
                    ]
                },
                {
                    category: 'Number of Drawers', subcategories: [
                        {category: 'One_DRWR', displayName: '1', tags: ['One_DRWR']},
                        {category: 'Two_DRWR', displayName: '2', tags: ['Two_DRWR']},
                        {category: 'Three_DRWR', displayName: '3', tags: ['Three_DRWR']},
                        {category: 'FourPlus_DRWR', displayName: '4+', tags: ['FourPlus_DRWR']},
                        {category: 'Internal', displayName: 'Internal', tags: ['Internal']}
                    ]
                },
                {
                    category: 'Number of Doors', subcategories: [
                        {category: 'One_DR', displayName: '1', tags: ['One_DR']},
                        {category: 'Two_DR', displayName: '2', tags: ['Two_DR']},
                        {category: 'Three_DR', displayName: '3', tags: ['Three_DR']},
                        {category: 'FourPlus_DR', displayName: '4+', tags: ['FourPlus_DR']}
                    ]
                },
                {
                    category: 'Shelves', subcategories: [
                        {category: 'Fixed', displayName: 'Fixed', tags: ['Fixed']},
                        {category: 'Adjustable', displayName: 'Adjustable', tags: ['Adjustable']},
                        {category: 'Internal_Counter', displayName: 'Internal Counter', tags: ['Internal_Counter']},
                        {category: 'Glass', displayName: 'Glass', tags: ['Glass']},
                        {category: 'Cantilever', displayName: 'Cantilever', tags: ['Cantilever']}
                    ]
                },
                {
                    category: 'Base', subcategories: [
                        {category: 'Leg_Levelers', displayName: 'Leg Levelers', tags: ['Leg_Levelers']},
                        {category: 'Plinth', displayName: 'Plinth', tags: ['Plinth']},
                        {category: 'Integrated', displayName: 'Integrated', tags: ['Integrated']}
                    ]
                },
                {
                    category: 'Hinge Type', subcategories: [
                        {category: 'Concealed', displayName: 'Concealed', tags: ['Concealed']},
                        {category: 'Exposed', displayName: 'Exposed', tags: ['Exposed']},
                        {category: 'Overlay', displayName: 'Overlay', tags: ['Overlay']},
                        {category: 'Inset', displayName: 'Inset', tags: ['Inset']},
                        {category: 'Specialty', displayName: 'Specialty', tags: ['Specialty']},
                        {category: 'Pivot', displayName: 'Pivot', tags: ['Pivot']}
                    ]
                },
                {
                    category: 'Opening Type', subcategories: [
                        {category: 'OpenShelving', displayName: 'Open Shelving', tags: ['OpenShelving']},
                        {category: 'Drawers', displayName: 'Drawers', tags: ['Drawers']},
                        {category: 'Doors', displayName: 'Doors', tags: ['Doors']},
                        {category: 'EmptyCabinet', displayName: 'Empty Cabinet', tags: ['EmptyCabinet']},
                        {category: 'Sliding_Door', displayName: 'Sliding Door', tags: ['Sliding_Door']}
                    ]
                },
                {
                    category: 'Mounting option', subcategories: [
                        {category: 'WallMount', displayName: 'Wall Mount', tags: ['WallMount']},
                        {category: 'FloorMount', displayName: 'Floor Mount', tags: ['FloorMount']}
                    ]
                }
            ]
        },
        {
            category: 'Materials and Finishes', subcategories: [
                {
                    category: 'Material Type', subcategories: [
                        {category: 'Solid_Wood', displayName: 'Solid Wood', tags: ['Solid_Wood']},
                        {category: 'MDF', displayName: 'MDF', tags: ['MDF']},
                        {category: 'Plywood', displayName: 'Plywood', tags: ['Plywood']},
                        {category: 'Particle_Board', displayName: 'Particle Board', tags: ['Particle_Board']},
                        {category: 'Metal', displayName: 'Metal', tags: ['Metal']},
                        {category: 'Glass', displayName: 'Glass', tags: ['Glass']}
                    ]
                },
                {
                    category: 'Finish Type', subcategories: [
                        {category: 'Paint', displayName: 'Paint', tags: ['Paint']},
                        {category: 'Stain', displayName: 'Stain', tags: ['Stain']},
                        {category: 'Veneer', displayName: 'Veneer', tags: ['Veneer']},
                        {category: 'Laminate', displayName: 'Laminate', tags: ['Laminate']},
                        {category: 'Melamine', displayName: 'Melamine', tags: ['Melamine']}
                    ]
                },
                {
                    category: 'Color Options', subcategories: [
                        {category: 'Yes_NO', displayName: 'Yes/NO', tags: ['Yes_NO']}
                    ]
                }
            ]
        },
        {
            category: 'Lighting and Accessories', subcategories: [
                {
                    category: 'Lighting Integrated', subcategories: [
                        {
                            category: 'LED_Strip_Under_Counter',
                            displayName: 'LED Strip Under Counter',
                            tags: ['LED_Strip_Under_Counter']
                        },
                        {category: 'LED_Puck', displayName: 'LED Puck', tags: ['LED_Puck']},
                        {category: 'LED_Vertical', displayName: 'LED Vertical', tags: ['LED_Vertical']},
                        {
                            category: 'LED_Internal_Shelf',
                            displayName: 'LED Internal Shelf',
                            tags: ['LED_Internal_Shelf']
                        }
                    ]
                },
                {
                    category: 'Kitchen Accessory', subcategories: [
                        {category: 'Cutlery_Tray', displayName: 'Cutlery Tray', tags: ['Cutlery_Tray']},
                        {category: 'Spice_Rack', displayName: 'Spice Rack', tags: ['Spice_Rack']},
                        {category: 'Plate_Holder', displayName: 'Plate Holder', tags: ['Plate_Holder']},
                        {category: 'Lid_Organizer', displayName: 'Lid Organizer', tags: ['Lid_Organizer']},
                        {category: 'Utensil_Tray', displayName: 'Utensil Tray', tags: ['Utensil_Tray']},
                        {
                            category: 'Pull_Out_Trash_Bin',
                            displayName: 'Pull Out Trash Bin',
                            tags: ['Pull_Out_Trash_Bin']
                        }
                    ]
                },
                {
                    category: 'Closet Accessory', subcategories: [
                        {category: 'Clothing_Rods', displayName: 'Clothing Rods', tags: ['Clothing_Rods']},
                        {category: 'Tie_Racks', displayName: 'Tie Racks', tags: ['Tie_Racks']},
                        {category: 'Belt_Racks', displayName: 'Belt Racks', tags: ['Belt_Racks']},
                        {category: 'Valet_Rods', displayName: 'Valet Rods', tags: ['Valet_Rods']},
                        {category: 'Jewelry_Trays', displayName: 'Jewelry Trays', tags: ['Jewelry_Trays']},
                        {category: 'Shoe_Racks', displayName: 'Shoe Racks', tags: ['Shoe_Racks']},
                        {category: 'Shoe_Shelves', displayName: 'Shoe Shelves', tags: ['Shoe_Shelves']},
                        {category: 'Shoe_Drawers', displayName: 'Shoe Drawers', tags: ['Shoe_Drawers']},
                        {category: 'Laundry_Hampers', displayName: 'Laundry Hampers', tags: ['Laundry_Hampers']},
                        {category: 'Ironing_Boards', displayName: 'Ironing Boards', tags: ['Ironing_Boards']},
                        {category: 'Drawer_Organizers', displayName: 'Drawer Organizers', tags: ['Drawer_Organizers']},
                        {category: 'Shelf_Dividers', displayName: 'Shelf Dividers', tags: ['Shelf_Dividers']},
                        {category: 'FileDrawer', displayName: 'File Drawer', tags: ['FileDrawer']},
                        {category: 'Hooks', displayName: 'Hooks', tags: ['Hooks']},
                        {
                            category: 'DockingDrawer_outlet',
                            displayName: 'Docking Drawer (outlet)',
                            tags: ['DockingDrawer_outlet']
                        },
                        {category: 'Stepladder', displayName: 'Stepladder', tags: ['Stepladder']}
                    ]
                }
            ]
        },
        {
            category: 'Additional Features', subcategories: [
                {
                    category: 'Drawer Type', subcategories: [
                        {category: 'SideMount', displayName: 'Side Mount', tags: ['SideMount']},
                        {category: 'Soft_Close', displayName: 'Soft Close', tags: ['Soft_Close']},
                        {category: 'Push_To_Open', displayName: 'Push To Open', tags: ['Push_To_Open']}
                    ]
                },
                {
                    category: 'Drawer Box Material', subcategories: [
                        {category: 'Solid_Wood', displayName: 'Solid Wood', tags: ['Solid_Wood']},
                        {category: 'Plywood', displayName: 'Plywood', tags: ['Plywood']},
                        {category: 'Metal', displayName: 'Metal', tags: ['Metal']}
                    ]
                },
                {
                    category: 'Size Range', subcategories: [
                        {category: 'HeightMinMax', displayName: 'Height min/max', tags: ['HeightMinMax']},
                        {category: 'WidthMinMax', displayName: 'Width min/max', tags: ['WidthMinMax']},
                        {category: 'DepthMinMax', displayName: 'Depth min/max', tags: ['DepthMinMax']}
                    ]
                }
            ]
        }
    ], []);


    const shuffleArray = useCallback((array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }, []);

    const numberMapToNumbers = {
        'one': '1', 'two': '2', 'three': '3', 'four': '4', 'five': '5', 'six': '6'
    };

    // const standardizeText = (text) => {
    //     return text.replace(/\b(one|two|three|four|five|six)\b/gi,
    //         match => {
    //             const lowercaseMatch = match.toLowerCase();
    //             return numberMapToNumbers[lowercaseMatch] || match;
    //         }
    //     );
    // };
    //
    //
    // const filteredCabinets = useMemo(() => {
    //     if (selectedFilters.length === 0 && !searchQuery) return isFirstLoad ? randomizedCabinets : allCabinets;
    //
    //     const typeAndFunctionFilters = selectedFilters.filter(filter =>
    //         memoizedCategories[0].subcategories.some(sub => sub.tags.includes(filter))
    //     );
    //
    //     const featureFilters = selectedFilters.filter(filter =>
    //         !typeAndFunctionFilters.includes(filter)
    //     );
    //
    //     const featureCategories = memoizedCategories.slice(1);
    //
    //     const standardizedSearchQuery = standardizeText(searchQuery);
    //     const searchTerms = standardizedSearchQuery.split(/\s+/).filter(term => term.length > 0);
    //
    //     const filtered = allCabinets.filter(cabinet => {
    //         const cabinetTags = cabinet.filterTags.split(',').map(tag => tag.trim());
    //
    //         const matchesTypeAndFunction = typeAndFunctionFilters.length === 0 ||
    //             typeAndFunctionFilters.some(filter => cabinetTags.includes(filter));
    //
    //         const matchesFeatures = featureCategories.every(category => {
    //             const categoryFilters = featureFilters.filter(filter =>
    //                 category.subcategories.some(sub =>
    //                     sub.subcategories
    //                         ? sub.subcategories.some(subSub => subSub.tags.includes(filter))
    //                         : sub.tags.includes(filter)
    //                 )
    //             );
    //
    //             return categoryFilters.length === 0 || categoryFilters.some(filter => cabinetTags.includes(filter));
    //         });
    //
    //         if (!searchQuery) return matchesTypeAndFunction && matchesFeatures;
    //
    //         const matchesSerialNumber = cabinet.serialNumber.toLowerCase().includes(searchQuery.toLowerCase());
    //
    //         const standardizedDescription = standardizeText(cabinet.description);
    //         const matchesDescription = searchTerms.every(term => standardizedDescription.includes(term));
    //
    //         return matchesTypeAndFunction && matchesFeatures && (matchesSerialNumber || matchesDescription);
    //     });
    //
    //     setIsFirstLoad(false);
    //     setTotalPages(Math.ceil(filtered.length / cardsPerPage));
    //     return filtered;
    const standardizeText = (text) => {
        return text.replace(/\b(one|two|three|four|five|six)\b/gi,
            match => {
                const lowercaseMatch = match.toLowerCase();
                return numberMapToNumbers[lowercaseMatch] || match;
            }
        );
    };

    const filteredCabinets = useMemo(() => {
        if (selectedFilters.length === 0 && !searchQuery) return isFirstLoad ? randomizedCabinets : allCabinets;

        const typeAndFunctionFilters = selectedFilters.filter(filter =>
            memoizedCategories[0].subcategories.some(sub => sub.tags.includes(filter))
        );

        const featureFilters = selectedFilters.filter(filter =>
            !typeAndFunctionFilters.includes(filter)
        );

        const featureCategories = memoizedCategories.slice(1);

        const standardizedSearchQuery = standardizeText(searchQuery.toLowerCase());
        const searchTerms = standardizedSearchQuery.split(/\s+/).filter(term => term.length > 0);

        const filtered = allCabinets.filter(cabinet => {
            const cabinetTags = cabinet.filterTags.split(',').map(tag => tag.trim().toLowerCase());

            const matchesTypeAndFunction = typeAndFunctionFilters.length === 0 ||
                typeAndFunctionFilters.some(filter => cabinetTags.includes(filter.toLowerCase()));

            const matchesFeatures = featureCategories.every(category => {
                const categoryFilters = featureFilters.filter(filter =>
                    category.subcategories.some(sub =>
                        sub.subcategories
                            ? sub.subcategories.some(subSub => subSub.tags.includes(filter))
                            : sub.tags.includes(filter)
                    )
                );

                return categoryFilters.length === 0 || categoryFilters.some(filter => cabinetTags.includes(filter.toLowerCase()));
            });

            if (!searchQuery) return matchesTypeAndFunction && matchesFeatures;

            const matchesSerialNumber = cabinet.serialNumber.toLowerCase().includes(searchQuery.toLowerCase());

            const standardizedDescription = standardizeText(cabinet.description.toLowerCase());
            const matchesDescription = searchTerms.every(term => standardizedDescription.includes(term));

            return matchesTypeAndFunction && matchesFeatures && (matchesSerialNumber || matchesDescription);
        });

        setIsFirstLoad(false);
        setTotalPages(Math.ceil(filtered.length / cardsPerPage));
        return filtered;
    }, [selectedFilters, allCabinets, randomizedCabinets, isFirstLoad, memoizedCategories, searchQuery]);

    const paginatedCabinets = useMemo(() => {
        const startIndex = (currentPage - 1) * cardsPerPage;
        const endIndex = startIndex + cardsPerPage;
        return filteredCabinets.slice(startIndex, endIndex);
    }, [currentPage, filteredCabinets]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({top: 0, behavior: "instant"});
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`page-item sr-pi ${i === currentPage ? 'active' : ''}`}>
                    <button className="page-link sr-pl" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <nav>
                <ul className="pagination sr-pagination justify-content-center">
                    <li className={`page-item sr-pi ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link sr-pl" onClick={() => handlePageChange(1)}>
                            First
                        </button>
                    </li>
                    {pages}
                    <li className={`page-item sr-pi ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link sr-pl" onClick={() => handlePageChange(totalPages)}>
                            Last
                        </button>
                    </li>
                </ul>
            </nav>
        );
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="showroom-page min-vh-100">
            <Helmet>
                <title>Kitchen & Closet Cabinet Collection | The Sealab</title>
                <meta name="description"
                      content="Explore the finest collection of kitchen and closet cabinets at The Sealab. Discover high-quality cabinets perfect for your home renovation projects. Visit our showroom to find your ideal cabinet."/>
                <meta name="keywords"
                      content="kitchen cabinets, closet cabinetss, cabinet showroom, high-quality cabinets, home renovation, cabinet store, The Sealab, buy cabinets online"/>
                <meta property="og:title" content="Showroom | The Sealab - Kitchen & Closet Cabinets"/>
                <meta property="og:description"
                      content="Explore the finest collection of kitchen and closet cabinets at The Sealab. Discover high-quality cabinets perfect for your home renovation projects. Visit our showroom to find your ideal cabinet."/>
                <meta property="og:url" content="https://www.thesealab.com/showroom"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="The Sealab"/>
            </Helmet>

            <Suspense fallback={<div>Loading..</div>}>
                {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
            </Suspense>


            {dataLoaded && (
                <div className="showroom-container">
                    <div className="filter-panel">
                        {memoizedCategories.map(({category, subcategories}) =>
                            renderCategory(category, subcategories)
                        )}
                    </div>

                    <div className="showroom-content">
                        <Suspense fallback={<div>Loading images..</div>}>
                            <div className="test">
                                Can't find what you're looking for? Contact us <a className="hyperlink"
                                                                                  href="/customer-support/contact">here</a> and
                                upload a sketch
                            </div>

                            <div className="showroom-search-container">
                                <input
                                    type="text"
                                    className="form-control showroom-search-input"
                                    placeholder="Search cabinets..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            {filteredCabinets.length === 0 ? (
                                <div className="showroom-no-results">
                                    No cabinets found matching your search criteria.
                                </div>
                            ) : (
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                                    {paginatedCabinets.map((cabinet, index) => (
                                        <div key={index} className="col">
                                            <div className="card base-card h-100 showroom-cstm-card"
                                                 style={{width: `${cardWidth}px`}}>
                                                <ImageCarousel cabinet={cabinet}/>
                                                <div className="card-overlay">
                                                    <h5 className="card-title">{cabinet.serialNumber}</h5>
                                                    <p className="card-description">{cabinet.description}</p>
                                                    <div className="quantity-input">
                                                        <label>Qty: </label>
                                                        <input
                                                            type="number"
                                                            min="1"
                                                            defaultValue={1}
                                                            className="form-control"
                                                            onChange={(e) => handleQuantityChange(cabinet.id, e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="button-group">
                                                        <button
                                                            className="btn btn-black showroom-btn-black"
                                                            onClick={() => handleConfigure(cabinet)}
                                                        >
                                                            Configure
                                                        </button>
                                                        <button
                                                            className="btn btn-black showroom-btn-black"
                                                            onClick={() => handleAddToCart(cabinet)}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {filteredCabinets.length > 0 && renderPagination()}

                        </Suspense>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowRoomComponent;