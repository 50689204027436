import React, {useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import RegisterService from "../api/RegisterService";
import {useDispatch, useSelector} from 'react-redux';
import {storeUser} from '../features/user/userSlice';
import {selectCart} from "../features/cart/cartSlice";
import {useNavigate} from "react-router-dom"; // Adjust the import path as per your project structure

const RegisterModal = ({show, handleClose, fromCheckout}) => {
    const [registrationData, setRegistrationData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState(''); // State to hold the email error message
    const [passwordError, setPasswordError] = useState(''); // State to hold the password error message
    const [confirmPasswordError, setConfirmPasswordError] = useState(''); // State to hold the confirm password error message

    const dispatch = useDispatch();
    const cart = useSelector(selectCart);

    const handleChange = (e) => {
        const {id, value} = e.target;
        setRegistrationData({...registrationData, [id]: value});
        if (id === "email") setEmailError(''); // Clear email error when user modifies the email
        if (id === "password" || id === "confirmPassword") {
            setPasswordError(''); // Clear password error when user modifies the password
            setConfirmPasswordError(''); // Clear confirm password error when user modifies the confirm password
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async () => {
        if (!validateEmail(registrationData.email)) {
            setEmailError('Invalid email address.');
            return;
        }

        if (!validatePassword(registrationData.password)) {
            setPasswordError('Password must be at least 8 characters long, contain one uppercase letter and one number.');
            return;
        }

        if (registrationData.password !== registrationData.confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return;
        }

        try {
            const response = await RegisterService(registrationData);
            if (response.data.statusCode === 404) {
                setEmailError('Email Already Exists'); // Set email error message
            } else if (response.data.statusCode === 200) {
                dispatch(storeUser({
                    user: response.data.userDTO, // Adjust according to the actual response structure
                    accessToken: response.data.token,
                    refreshToken: response.data.refreshToken,
                    cart: cart, // Assuming a new user has an empty cart
                    role: response.data.userDTO.role
                }));
                if (fromCheckout) {
                    navigate('/checkout');
                }
                handleClose(); // Close modal on successful registration
            }
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{color: 'black'}}>Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="firstName">
                        <Form.Label style={{color: 'black'}}>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="lastName">
                        <Form.Label style={{color: 'black'}}>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter last name"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="email">
                        <Form.Label style={{color: 'black'}}>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            onChange={handleChange}
                            isInvalid={!!emailError} // Bootstrap's way to indicate field error
                        />
                        <Form.Control.Feedback type="invalid" style={{color: 'red'}}>
                            {emailError}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Label style={{color: 'black'}}>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Create password"
                            onChange={handleChange}
                            isInvalid={!!passwordError} // Bootstrap's way to indicate field error
                        />
                        <Form.Control.Feedback type="invalid" style={{color: 'red'}}>
                            {passwordError}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="confirmPassword">
                        <Form.Label style={{color: 'black'}}>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            onChange={handleChange}
                            isInvalid={!!confirmPasswordError} // Bootstrap's way to indicate field error
                        />
                        <Form.Control.Feedback type="invalid" style={{color: 'red'}}>
                            {confirmPasswordError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    style={{backgroundColor: 'black', color: 'white', width: '100%'}}>
                    Register
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegisterModal;
