// import React, {useEffect, useState} from 'react';
// import {Alert, Button, Card, Col, Container, Form, Modal, Nav, Row, Spinner, Table} from 'react-bootstrap';
// import axios from 'axios';
// import './OrderManagementStyles.css';
// import NavbarComponent from "../NavBarComponents/NavbarComponent";
// import NavbarLoggedInComponent from "../NavBarComponents/NavbarLoggedInComponent";
// import {useSelector} from "react-redux";
// import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
// import DataService from "../../api/DataService";
//
// const OrderManagementComponent = () => {
//     const userLoggedIn = UserLoggedIn();
//     const token = useSelector(state => state.user.accessToken);
//
//     const [orderIdInput, setOrderIdInput] = useState('');
//     const [order, setOrder] = useState(null);
//     const [articles, setArticles] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [searchError, setSearchError] = useState('');
//     const [imageExistsMap, setImageExistsMap] = useState({});
//     const [selectedArticles, setSelectedArticles] = useState(new Set());
//     const [searchFilter, setSearchFilter] = useState('');
//     const [materials, setMaterials] = useState([]);
//     const [edgebandingOptions, setEdgebandingOptions] = useState([]);
//     const [activeTab, setActiveTab] = useState('materials');
//     const [saving, setSaving] = useState(false);
//     const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
//     const [showNewEdgebandingModal, setShowNewEdgebandingModal] = useState(false);
//     const [newMaterial, setNewMaterial] = useState({
//         name: '',
//         description: '',
//         thickness: '',
//         rawCost: '',
//         visible: '1'
//     });
//     const [newEdgebanding, setNewEdgebanding] = useState({
//         imosName: '',
//         englishDescription: '',
//         visible: '1'
//     })
//     const baseURL = process.env.REACT_APP_BASEURL;
//     const imageURL = process.env.REACT_APP_IMAGEURL;
//     const thumbNail = process.env.REACT_APP_THMBNAIL;
//
//     useEffect(() => {
//         fetchMaterials();
//         fetchEdgebandingOptions();
//     }, []);
//
//     const handleNewMaterialSubmit = async () => {
//         try {
//             // Add API call here to create new material
//             const response = await DataService.createNewMaterial(newMaterial, token);
//             console.log('status: ', response.status)
//             setShowNewMaterialModal(false);
//             await fetchMaterials(); // Refresh materials list
//             setNewMaterial({
//                 name: '',
//                 description: '',
//                 thickness: '',
//                 rawCost: '',
//                 visible: '1'
//             });
//         } catch (error) {
//             console.error('Error creating new material:', error);
//         }
//     };
//
//     const handleNewEdgebandingSubmit = async () => {
//         try {
//             // Add API call here to create new edgebanding
//             await DataService.createNewEdgebanding(newEdgebanding, token);
//             setShowNewEdgebandingModal(false);
//             await fetchEdgebandingOptions(); // Refresh edgebanding list
//             setNewEdgebanding({
//                 imosName: '',
//                 englishDescription: '',
//                 visible: '1'
//             });
//         } catch (error) {
//             console.error('Error creating new edgebanding:', error);
//         }
//     };
//
//     const fetchMaterials = async () => {
//         try {
//             const response = await DataService.getAllMaterials();
//             const sortedMaterials = response.sort((a, b) => {
//                 if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
//                     return -1;
//                 } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
//                     return 1;
//                 }
//                 return a.description.localeCompare(b.description);
//             });
//             setMaterials(sortedMaterials);
//         } catch (error) {
//             console.error('Error fetching materials:', error);
//         }
//     };
//
//     const fetchEdgebandingOptions = async () => {
//         try {
//             const response = await DataService.getAllEdgebanding();
//             setEdgebandingOptions(response);
//         } catch (error) {
//             console.error('Error fetching edgebanding options:', error);
//         }
//     };
//
//     const handleSearch = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         await fetchOrderSummary(orderIdInput);
//     };
//
//     const fetchOrderSummary = async (orderId) => {
//         try {
//             const response = await axios.get(`${baseURL}/api/orders/${orderId}/summary`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             if (response.data.order) {
//                 setOrder(response.data.order);
//                 setArticles(response.data.articles);
//                 checkImagesExistence(response.data.articles);
//                 setSearchError('');
//                 setSelectedArticles(new Set());
//             } else {
//                 setSearchError('Order not found');
//                 setOrder(null);
//                 setArticles([]);
//             }
//         } catch (error) {
//             console.error('Error fetching order summary:', error);
//             setSearchError(error.response?.status === 404
//                 ? 'Order not found'
//                 : 'An error occurred while fetching the order. Please try again.');
//             setOrder(null);
//             setArticles([]);
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     const handleSaveChanges = async () => {
//         setSaving(true);
//         try {
//             const updatedOrder = {
//                 ...order,
//                 articles: articles
//             };
//             setOrder(updatedOrder);
//
//             await axios.post(`${baseURL}/api/internal/custom-materials`, updatedOrder, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//         } catch (error) {
//             console.error('Error saving changes:', error);
//             setSearchError('Failed to save changes. Please try again.');
//         } finally {
//             setSaving(false);
//         }
//     };
//
//     useEffect(() => {
//         console.log(order)
//     }, [order])
//
//     const handleMaterialChange = (type, value, positionName) => {
//         const updatedArticles = articles.map(article => {
//             if (article.positionName === positionName) {
//                 return {
//                     ...article,
//                     [type]: value
//                 };
//             }
//             return article;
//         });
//         setArticles(updatedArticles);
//     };
//
//     const handleBulkMaterialChange = (type, value) => {
//         const updatedArticles = articles.map(article => {
//             if (selectedArticles.has(article.positionName)) {
//                 return {
//                     ...article,
//                     [type]: value
//                 };
//             }
//             return article;
//         });
//         setArticles(updatedArticles);
//     };
//
//     const handleEdgebandingChange = (type, value, positionName) => {
//         const updatedArticles = articles.map(article => {
//             if (article.positionName === positionName) {
//                 return {
//                     ...article,
//                     [type]: value
//                 };
//             }
//             return article;
//         });
//         setArticles(updatedArticles);
//     };
//
//     const handleBulkEdgebandingChange = (type, value) => {
//         const updatedArticles = articles.map(article => {
//             if (selectedArticles.has(article.positionName)) {
//                 return {
//                     ...article,
//                     [type]: value
//                 };
//             }
//             return article;
//         });
//         setArticles(updatedArticles);
//     };
//
//     const checkImagesExistence = async (articles) => {
//         const map = {};
//         const promises = articles.map(async article => {
//             const url = `${thumbNail}${orderIdInput}/${article.positionName}.png`;
//             try {
//                 const response = await fetch(url, {method: 'HEAD'});
//                 map[article.positionName] = response.ok;
//             } catch (error) {
//                 console.error('Error checking image existence:', error);
//                 map[article.positionName] = false;
//             }
//         });
//
//         await Promise.all(promises);
//         setImageExistsMap(map);
//     };
//
//     const handleSelectAll = (e) => {
//         if (e.target.checked) {
//             const filteredArticles = articles
//                 .filter(article =>
//                     article.positionName.toLowerCase().includes(searchFilter.toLowerCase())
//                 )
//                 .map(article => article.positionName);
//             setSelectedArticles(new Set(filteredArticles));
//         } else {
//             setSelectedArticles(new Set());
//         }
//     };
//
//     const handleSelectArticle = (positionName) => {
//         const newSelected = new Set(selectedArticles);
//         if (newSelected.has(positionName)) {
//             newSelected.delete(positionName);
//         } else {
//             newSelected.add(positionName);
//         }
//         setSelectedArticles(newSelected);
//     };
//
//     const filteredArticles = articles.filter(article =>
//         article.positionName.toLowerCase().includes(searchFilter.toLowerCase())
//     );
//
//     const isAllSelected = filteredArticles.length > 0 &&
//         filteredArticles.every(article => selectedArticles.has(article.positionName));
//
//     const renderMaterialsTable = () => (
//         <>
//             <div className="mb-3">
//                 <Button
//                     variant="btn btn-dark"
//                     onClick={() => setShowNewMaterialModal(true)}
//                 >
//                     Create New Material
//                 </Button>
//             </div>
//             <Table responsive className="om-table">
//                 <thead>
//                 <tr>
//                     <th>
//                         <Form.Check
//                             type="checkbox"
//                             checked={isAllSelected}
//                             onChange={handleSelectAll}
//                             className="om-checkbox"
//                         />
//                     </th>
//                     <th>Preview</th>
//                     <th>Position Name</th>
//                     <th>Case Material</th>
//                     <th>Front Material</th>
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {filteredArticles.map((article, index) => (
//                     <tr key={index} className="om-article-row">
//                         <td>
//                             <Form.Check
//                                 type="checkbox"
//                                 checked={selectedArticles.has(article.positionName)}
//                                 onChange={() => handleSelectArticle(article.positionName)}
//                                 className="om-checkbox"
//                             />
//                         </td>
//                         <td className="om-preview-cell">
//                             {imageExistsMap[article.positionName] ? (
//                                 <img
//                                     src={`${thumbNail}${orderIdInput}/${article.positionName}.png`}
//                                     alt={article.positionName}
//                                     className="om-preview-image"
//                                 />
//                             ) : (
//                                 <div className="om-no-image">No Preview</div>
//                             )}
//                         </td>
//                         <td>{article.positionName}</td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     value={article.caseMaterial}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkMaterialChange('caseMaterial', e.target.value);
//                                         } else {
//                                             handleMaterialChange('caseMaterial', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {/*<option value={article.caseMaterial}>{article.caseMaterial}</option>*/}
//                                     {materials.map((material, idx) => (
//                                         <option key={idx} value={material.description}>
//                                             {material.description}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     value={article.frontMaterial}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkMaterialChange('frontMaterial', e.target.value);
//                                         } else {
//                                             handleMaterialChange('frontMaterial', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {/*<option value={article.frontMaterial}>{article.frontMaterial}</option>*/}
//                                     {materials.map((material, idx) => (
//                                         <option key={idx} value={material.description}>
//                                             {material.description}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                     </tr>
//                 ))}
//                 </tbody>
//             </Table>
//         </>
//     );
//
//     const renderEdgebandingTable = () => (
//         <>
//             <div className="mb-3">
//                 <Button
//                     variant="btn btn-dark"
//                     onClick={() => setShowNewEdgebandingModal(true)}
//                 >
//                     Create New Edgebanding
//                 </Button>
//             </div>
//             <Table responsive className="om-table">
//                 <thead>
//                 <tr>
//                     <th>
//                         <Form.Check
//                             type="checkbox"
//                             checked={isAllSelected}
//                             onChange={handleSelectAll}
//                             className="om-checkbox"
//                         />
//                     </th>
//                     <th>Preview</th>
//                     <th>Position Name</th>
//                     <th>Case Edge</th>
//                     <th>Front Edge</th>
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {filteredArticles.map((article, index) => (
//                     <tr key={index} className="om-article-row">
//                         <td>
//                             <Form.Check
//                                 type="checkbox"
//                                 checked={selectedArticles.has(article.positionName)}
//                                 onChange={() => handleSelectArticle(article.positionName)}
//                                 className="om-checkbox"
//                             />
//                         </td>
//                         <td className="om-preview-cell">
//                             {imageExistsMap[article.positionName] ? (
//                                 <img
//                                     src={`${thumbNail}${orderIdInput}/${article.positionName}.png`}
//                                     alt={article.positionName}
//                                     className="om-preview-image"
//                                 />
//                             ) : (
//                                 <div className="om-no-image">No Preview</div>
//                             )}
//                         </td>
//                         <td>{article.positionName}</td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     value={article.caseEdge}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkEdgebandingChange('caseEdge', e.target.value);
//                                         } else {
//                                             handleEdgebandingChange('caseEdge', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {/*<option value={article.caseEdge}>{article.caseEdge}</option>*/}
//                                     {edgebandingOptions.map((edge, idx) => (
//                                         <option key={idx} value={edge.englishDescription}>
//                                             {edge.englishDescription}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     value={article.frontEdge}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkEdgebandingChange('frontEdge', e.target.value);
//                                         } else {
//                                             handleEdgebandingChange('frontEdge', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {/*<option value={article.frontEdge}>{article.frontEdge}</option>*/}
//                                     {edgebandingOptions.map((edge, idx) => (
//                                         <option key={idx} value={edge.englishDescription}>
//                                             {edge.englishDescription}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                     </tr>
//                 ))}
//                 </tbody>
//             </Table>
//         </>
//     );
//
//     if (!userLoggedIn) {
//         return <NavbarComponent/>;
//     }
//
//     return (
//         <>
//             <NavbarLoggedInComponent/>
//             <Container fluid className="om-container">
//                 <Row className="om-header-row">
//                     <Col md={4} className="mb-3">
//                         <Form onSubmit={handleSearch} className="om-search-form">
//                             <div className="om-search-wrapper">
//                                 <Form.Control
//                                     type="text"
//                                     value={orderIdInput}
//                                     onChange={(e) => setOrderIdInput(e.target.value)}
//                                     placeholder="Enter Order ID"
//                                     className="om-input"
//                                 />
//                                 <Button
//                                     type="submit"
//                                     className="om-search-btn"
//                                     disabled={loading}
//                                 >
//                                     {loading ? (
//                                         <Spinner animation="border" size="sm"/>
//                                     ) : (
//                                         'Search'
//                                     )}
//                                 </Button>
//                             </div>
//                         </Form>
//                     </Col>
//                 </Row>
//
//                 {searchError && (
//                     <Row>
//                         <Col>
//                             <Alert variant="danger" className="om-alert">
//                                 {searchError}
//                             </Alert>
//                         </Col>
//                     </Row>
//                 )}
//
//                 {order && (
//                     <div className="om-order-content">
//                         <Row className="mb-4">
//                             <Col md={6}>
//                                 <Card className="om-order-details">
//                                     <Card.Header className="om-card-header">
//                                         <Card.Title>Order Details</Card.Title>
//                                     </Card.Header>
//                                     <Card.Body className="om-card-body">
//                                         <Table className="om-details-table" borderless>
//                                             <tbody>
//                                             <tr>
//                                                 <td className="om-details-label">Order ID:</td>
//                                                 <td className="om-details-value">{order.orderId}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Project Name:</td>
//                                                 <td className="om-details-value">{order.projectName}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Purchase Order:</td>
//                                                 <td className="om-details-value">{order.purchaseOrder}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Status:</td>
//                                                 <td className="om-details-value">{order.status}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Date:</td>
//                                                 <td className="om-details-value">{new Date(order.date).toLocaleDateString()}</td>
//                                             </tr>
//                                             </tbody>
//                                         </Table>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                             <Col md={6}>
//                                 <Card className="om-special-instructions">
//                                     <Card.Header className="om-card-header">
//                                         <Card.Title>Special Instructions</Card.Title>
//                                     </Card.Header>
//                                     <Card.Body className="om-card-body">
//                                         <p className="om-instructions-text">
//                                             {order.specialInstructions || 'No special instructions provided'}
//                                         </p>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//
//                         <Row className="mb-3">
//                             <Col md={6}>
//                                 <Form.Group className="om-filter-group">
//                                     <Form.Control
//                                         type="text"
//                                         placeholder="Filter by Position Name"
//                                         value={searchFilter}
//                                         onChange={(e) => setSearchFilter(e.target.value)}
//                                         className="om-filter-input"
//                                     />
//                                 </Form.Group>
//                             </Col>
//                             <Col md={6} className="text-end">
//                                 <Button
//                                     onClick={handleSaveChanges}
//                                     disabled={saving}
//                                     className="om-save-btn"
//                                     variant="dark"
//                                 >
//                                     {saving ? (
//                                         <>
//                                             <Spinner animation="border" size="sm" className="me-2"/>
//                                             Saving Changes...
//                                         </>
//                                     ) : (
//                                         'Save Changes'
//                                     )}
//                                 </Button>
//                             </Col>
//                         </Row>
//
//                         <Row className="om-articles-section">
//                             <Col>
//                                 <Card className="om-table-card">
//                                     <Card.Header className="om-card-header">
//                                         <Nav variant="tabs" className="om-nav-tabs">
//                                             <Nav.Item>
//                                                 <Nav.Link
//                                                     className={`om-nav-link ${activeTab === 'materials' ? 'active' : ''}`}
//                                                     onClick={() => setActiveTab('materials')}
//                                                 >
//                                                     Materials
//                                                 </Nav.Link>
//                                             </Nav.Item>
//                                             <Nav.Item>
//                                                 <Nav.Link
//                                                     className={`om-nav-link ${activeTab === 'edgebanding' ? 'active' : ''}`}
//                                                     onClick={() => setActiveTab('edgebanding')}
//                                                 >
//                                                     Edgebanding
//                                                 </Nav.Link>
//                                             </Nav.Item>
//                                         </Nav>
//                                     </Card.Header>
//                                     <Card.Body className="om-table-wrapper">
//                                         {activeTab === 'materials' ? renderMaterialsTable() : renderEdgebandingTable()}
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </div>
//                 )}
//
//                 <Modal show={showNewMaterialModal} onHide={() => setShowNewMaterialModal(false)}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Create New Material</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <Form>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Imos Name</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newMaterial.name}
//                                     onChange={(e) => setNewMaterial({...newMaterial, name: e.target.value})}
//                                 />
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Description</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newMaterial.description}
//                                     onChange={(e) => setNewMaterial({...newMaterial, description: e.target.value})}
//                                 />
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Thickness (mm)</Form.Label>
//                                 <Form.Control
//                                     type="number"
//                                     step="0.01"
//                                     value={newMaterial.thickness}
//                                     onChange={(e) => {
//                                         const value = e.target.value;
//                                         if (value === '' || /^\d*\.?\d*$/.test(value)) {
//                                             setNewMaterial({...newMaterial, thickness: value});
//                                         }
//                                     }}
//                                 />
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Raw Cost</Form.Label>
//                                 <Form.Control
//                                     type="number"
//                                     step="0.01"
//                                     value={newMaterial.rawCost}
//                                     onChange={(e) => {
//                                         const value = e.target.value;
//                                         if (value === '' || /^\d*\.?\d*$/.test(value)) {
//                                             setNewMaterial({...newMaterial, rawCost: value});
//                                         }
//                                     }}
//                                 />
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Visible</Form.Label>
//                                 <Form.Select
//                                     value={newMaterial.visible}
//                                     onChange={(e) => setNewMaterial({...newMaterial, visible: e.target.value})}
//                                 >
//                                     <option value="1">1</option>
//                                     <option value="0">0</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </Form>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={() => setShowNewMaterialModal(false)}>
//                             Cancel
//                         </Button>
//                         <Button variant="btn btn-dark" onClick={handleNewMaterialSubmit}>
//                             Create Material
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>
//
//                 <Modal show={showNewEdgebandingModal} onHide={() => setShowNewEdgebandingModal(false)}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Create New Edgebanding</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <Form>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Imos Name</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newEdgebanding.imosName}
//                                     onChange={(e) => setNewEdgebanding({...newEdgebanding, imosName: e.target.value})}
//                                 />
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Description</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newEdgebanding.englishDescription}
//                                     onChange={(e) => setNewEdgebanding({
//                                         ...newEdgebanding,
//                                         englishDescription: e.target.value
//                                     })}
//                                 />
//                             </Form.Group>
//                             {/*<Form.Group className="mb-3">*/}
//                             {/*    <Form.Label>Thickness (mm)</Form.Label>*/}
//                             {/*    <Form.Control*/}
//                             {/*        type="number"*/}
//                             {/*        step="0.01"*/}
//                             {/*        value={newMaterial.thickness}*/}
//                             {/*        onChange={(e) => {*/}
//                             {/*            const value = e.target.value;*/}
//                             {/*            if (value === '' || /^\d*\.?\d*$/.test(value)) {*/}
//                             {/*                setNewMaterial({...newMaterial, thickness: value});*/}
//                             {/*            }*/}
//                             {/*        }}*/}
//                             {/*    />*/}
//                             {/*</Form.Group>*/}
//                             {/*<Form.Group className="mb-3">*/}
//                             {/*    <Form.Label>Raw Cost</Form.Label>*/}
//                             {/*    <Form.Control*/}
//                             {/*        type="number"*/}
//                             {/*        step="0.01"*/}
//                             {/*        value={newMaterial.rawCost}*/}
//                             {/*        onChange={(e) => {*/}
//                             {/*            const value = e.target.value;*/}
//                             {/*            if (value === '' || /^\d*\.?\d*$/.test(value)) {*/}
//                             {/*                setNewMaterial({...newMaterial, rawCost: value});*/}
//                             {/*            }*/}
//                             {/*        }}*/}
//                             {/*    />*/}
//                             {/*</Form.Group>*/}
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Visible</Form.Label>
//                                 <Form.Select
//                                     value={newEdgebanding.visible}
//                                     onChange={(e) => setNewEdgebanding({...newEdgebanding, visible: e.target.value})}
//                                 >
//                                     <option value="1">1</option>
//                                     <option value="0">0</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </Form>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={() => setShowNewEdgebandingModal(false)}>
//                             Cancel
//                         </Button>
//                         <Button variant="btn btn-dark" onClick={handleNewEdgebandingSubmit}>
//                             Create Edgebanding
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>
//             </Container>
//         </>
//     );
// };
//
// export default OrderManagementComponent;


import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, Modal, Nav, Row, Spinner, Table} from 'react-bootstrap';
import axios from 'axios';
import './OrderManagementStyles.css';
import NavbarComponent from "../NavBarComponents/NavbarComponent";
import NavbarLoggedInComponent from "../NavBarComponents/NavbarLoggedInComponent";
import {useSelector} from "react-redux";
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import DataService from "../../api/DataService";

const OrderManagementComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const token = useSelector(state => state.user.accessToken);

    const [orderIdInput, setOrderIdInput] = useState('');
    const [order, setOrder] = useState(null);
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchError, setSearchError] = useState('');
    const [imageExistsMap, setImageExistsMap] = useState({});
    const [selectedArticles, setSelectedArticles] = useState(new Set());
    const [searchFilter, setSearchFilter] = useState('');
    const [materials, setMaterials] = useState([]);
    const [edgebandingOptions, setEdgebandingOptions] = useState([]);
    const [activeTab, setActiveTab] = useState('materials');
    const [saving, setSaving] = useState(false);
    const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
    const [showNewEdgebandingModal, setShowNewEdgebandingModal] = useState(false);
    const [materialValidation, setMaterialValidation] = useState({});
    const [edgebandingValidation, setEdgebandingValidation] = useState({});
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [newMaterial, setNewMaterial] = useState({
        name: '',
        description: '',
        thickness: '',
        rawCost: '',
        visible: '1'
    });
    const [newEdgebanding, setNewEdgebanding] = useState({
        imosName: '',
        englishDescription: '',
        visible: '1'
    });
    const baseURL = process.env.REACT_APP_BASEURL;
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;

    useEffect(() => {
        fetchMaterials();
        fetchEdgebandingOptions();
    }, []);

    const validateMaterialForm = () => {
        const errors = {};
        if (!newMaterial.name.trim()) errors.name = 'Name is required';
        if (!newMaterial.description.trim()) errors.description = 'Description is required';
        if (!newMaterial.thickness) errors.thickness = 'Thickness is required';
        if (!newMaterial.rawCost) errors.rawCost = 'Raw cost is required';

        setMaterialValidation(errors);
        return Object.keys(errors).length === 0;
    };

    const validateEdgebandingForm = () => {
        const errors = {};
        if (!newEdgebanding.imosName.trim()) errors.imosName = 'Imos name is required';
        if (!newEdgebanding.englishDescription.trim()) errors.englishDescription = 'Description is required';

        setEdgebandingValidation(errors);
        return Object.keys(errors).length === 0;
    };

    const handleNewMaterialSubmit = async () => {
        if (!validateMaterialForm()) return;

        try {
            const response = await DataService.createNewMaterial(newMaterial, token);
            if (response.status === 200) {
                setShowSuccessAlert(true);
                setAlertMessage('Material created successfully!');
                setShowNewMaterialModal(false);
                await fetchMaterials();
                setNewMaterial({
                    name: '',
                    description: '',
                    thickness: '',
                    rawCost: '',
                    visible: '1'
                });
            }
        } catch (error) {
            setShowErrorAlert(true);
            setAlertMessage('Failed to create material. Please try again.');
            console.error('Error creating new material:', error);
        }
    };

    const handleNewEdgebandingSubmit = async () => {
        if (!validateEdgebandingForm()) return;

        try {
            const response = await DataService.createNewEdgebanding(newEdgebanding, token);
            if (response.status === 200) {
                setShowSuccessAlert(true);
                setAlertMessage('Edgebanding created successfully!');
                setShowNewEdgebandingModal(false);
                await fetchEdgebandingOptions();
                setNewEdgebanding({
                    imosName: '',
                    englishDescription: '',
                    visible: '1'
                });
            }
        } catch (error) {
            setShowErrorAlert(true);
            setAlertMessage('Failed to create edgebanding. Please try again.');
            console.error('Error creating new edgebanding:', error);
        }
    };

    const fetchMaterials = async () => {
        try {
            const response = await DataService.getAllMaterials();
            const sortedMaterials = response.sort((a, b) => {
                if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                    return -1;
                } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                    return 1;
                }
                return a.description.localeCompare(b.description);
            });
            setMaterials(sortedMaterials);
        } catch (error) {
            console.error('Error fetching materials:', error);
        }
    };

    const fetchEdgebandingOptions = async () => {
        try {
            const response = await DataService.getAllEdgebanding();
            setEdgebandingOptions(response);
        } catch (error) {
            console.error('Error fetching edgebanding options:', error);
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        await fetchOrderSummary(orderIdInput);
    };

    const fetchOrderSummary = async (orderId) => {
        try {
            const response = await axios.get(`${baseURL}/api/orders/${orderId}/summary`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.order) {
                setOrder(response.data.order);
                setArticles(response.data.articles);
                checkImagesExistence(response.data.articles);
                setSearchError('');
                setSelectedArticles(new Set());
            } else {
                setSearchError('Order not found');
                setOrder(null);
                setArticles([]);
            }
        } catch (error) {
            console.error('Error fetching order summary:', error);
            setSearchError(error.response?.status === 404
                ? 'Order not found'
                : 'An error occurred while fetching the order. Please try again.');
            setOrder(null);
            setArticles([]);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            const updatedOrder = {
                ...order,
                articles: articles
            };
            setOrder(updatedOrder);

            await axios.post(`${baseURL}/api/internal/custom-materials`, updatedOrder, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        } catch (error) {
            console.error('Error saving changes:', error);
            setSearchError('Failed to save changes. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        console.log(order);
    }, [order]);

    const handleMaterialChange = (type, value, positionName) => {
        const updatedArticles = articles.map(article => {
            if (article.positionName === positionName) {
                return {
                    ...article,
                    [type]: value
                };
            }
            return article;
        });
        setArticles(updatedArticles);
    };

    const handleBulkMaterialChange = (type, value) => {
        const updatedArticles = articles.map(article => {
            if (selectedArticles.has(article.positionName)) {
                return {
                    ...article,
                    [type]: value
                };
            }
            return article;
        });
        setArticles(updatedArticles);
    };

    const handleEdgebandingChange = (type, value, positionName) => {
        const updatedArticles = articles.map(article => {
            if (article.positionName === positionName) {
                return {
                    ...article,
                    [type]: value
                };
            }
            return article;
        });
        setArticles(updatedArticles);
    };

    const handleBulkEdgebandingChange = (type, value) => {
        const updatedArticles = articles.map(article => {
            if (selectedArticles.has(article.positionName)) {
                return {
                    ...article,
                    [type]: value
                };
            }
            return article;
        });
        setArticles(updatedArticles);
    };

    const checkImagesExistence = async (articles) => {
        const map = {};
        const promises = articles.map(async article => {
            const url = `${thumbNail}${orderIdInput}/${article.positionName}.png`;
            try {
                const response = await fetch(url, {method: 'HEAD'});
                map[article.positionName] = response.ok;
            } catch (error) {
                console.error('Error checking image existence:', error);
                map[article.positionName] = false;
            }
        });

        await Promise.all(promises);
        setImageExistsMap(map);
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const filteredArticles = articles
                .filter(article =>
                    article.positionName.toLowerCase().includes(searchFilter.toLowerCase())
                )
                .map(article => article.positionName);
            setSelectedArticles(new Set(filteredArticles));
        } else {
            setSelectedArticles(new Set());
        }
    };

    const handleSelectArticle = (positionName) => {
        const newSelected = new Set(selectedArticles);
        if (newSelected.has(positionName)) {
            newSelected.delete(positionName);
        } else {
            newSelected.add(positionName);
        }
        setSelectedArticles(newSelected);
    };

    const filteredArticles = articles.filter(article =>
        article.positionName.toLowerCase().includes(searchFilter.toLowerCase())
    );

    const isAllSelected = filteredArticles.length > 0 &&
        filteredArticles.every(article => selectedArticles.has(article.positionName));

    const renderMaterialsTable = () => (
        <>
            <div className="mb-3">
                <Button
                    variant="btn btn-dark"
                    onClick={() => setShowNewMaterialModal(true)}
                >
                    Create New Material
                </Button>
            </div>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>
                        <Form.Check
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            className="om-checkbox"
                        />
                    </th>
                    <th>Preview</th>
                    <th>Position Name</th>
                    <th>Case Material</th>
                    <th>Front Material</th>
                    <th>Back Panel Material</th>
                </tr>
                </thead>
                <tbody>
                {filteredArticles.map((article, index) => (
                    <tr key={index} className="om-article-row">
                        <td>
                            <Form.Check
                                type="checkbox"
                                checked={selectedArticles.has(article.positionName)}
                                onChange={() => handleSelectArticle(article.positionName)}
                                className="om-checkbox"
                            />
                        </td>
                        <td className="om-preview-cell">
                            {imageExistsMap[article.positionName] ? (
                                <img
                                    src={`${thumbNail}${orderIdInput}/${article.positionName}.png`}
                                    alt={article.positionName}
                                    className="om-preview-image"
                                />
                            ) : (
                                <div className="om-no-image">No Preview</div>
                            )}
                        </td>
                        <td>{article.positionName}</td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    value={article.caseMaterial}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkMaterialChange('caseMaterial', e.target.value);
                                        } else {
                                            handleMaterialChange('caseMaterial', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {materials.map((material, idx) => (
                                        <option key={idx} value={material.description}>
                                            {material.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    value={article.frontMaterial}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkMaterialChange('frontMaterial', e.target.value);
                                        } else {
                                            handleMaterialChange('frontMaterial', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {materials.map((material, idx) => (
                                        <option key={idx} value={material.description}>
                                            {material.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    value={article.backPanelMaterial}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkMaterialChange('backPanelMaterial', e.target.value);
                                        } else {
                                            handleMaterialChange('backPanelMaterial', e.target.value, article.positionName);
                                        }

                                    }}
                                >
                                    {materials.map((material, idx) => (
                                        <option key={idx} value={material.description}>
                                            {material.description}
                                        </option>
                                    ))}

                                </Form.Select>

                            </Form.Group>
                        </td>

                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );

    const renderEdgebandingTable = () => (
        <>
            <div className="mb-3">
                <Button
                    variant="btn btn-dark"
                    onClick={() => setShowNewEdgebandingModal(true)}
                >
                    Create New Edgebanding
                </Button>
            </div>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>
                        <Form.Check
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={handleSelectAll}
                            className="om-checkbox"/>
                    </th>
                    <th>Preview</th>
                    <th>Position Name</th>
                    <th>Case Edge</th>
                    <th>Front Edge</th>
                </tr>
                </thead>
                <tbody>
                {filteredArticles.map((article, index) => (
                    <tr key={index} className="om-article-row">
                        <td>
                            <Form.Check
                                type="checkbox"
                                checked={selectedArticles.has(article.positionName)}
                                onChange={() => handleSelectArticle(article.positionName)}
                                className="om-checkbox"
                            />
                        </td>
                        <td className="om-preview-cell">
                            {imageExistsMap[article.positionName] ? (
                                <img
                                    src={`${thumbNail}${orderIdInput}/${article.positionName}.png`}
                                    alt={article.positionName}
                                    className="om-preview-image"
                                />
                            ) : (
                                <div className="om-no-image">No Preview</div>
                            )}
                        </td>
                        <td>{article.positionName}</td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    value={article.caseEdge || ''}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkEdgebandingChange('caseEdge', e.target.value);
                                        } else {
                                            handleEdgebandingChange('caseEdge', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {edgebandingOptions.map((edge, idx) => (
                                        <option key={idx} value={edge.englishDescription}>
                                            {edge.englishDescription}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    value={article.frontEdge || ''}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkEdgebandingChange('frontEdge', e.target.value);
                                        } else {
                                            handleEdgebandingChange('frontEdge', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {edgebandingOptions.map((edge, idx) => (
                                        <option key={idx} value={edge.englishDescription}>
                                            {edge.englishDescription}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );

    if (!userLoggedIn) {
        return <NavbarComponent/>;
    }

    return (
        <>
            <NavbarLoggedInComponent/>
            <Container fluid className="om-container">
                {showSuccessAlert && (
                    <Alert
                        variant="success"
                        onClose={() => setShowSuccessAlert(false)}
                        dismissible
                        className="mt-3"
                    >
                        {alertMessage}
                    </Alert>
                )}
                {showErrorAlert && (
                    <Alert
                        variant="danger"
                        onClose={() => setShowErrorAlert(false)}
                        dismissible
                        className="mt-3"
                    >
                        {alertMessage}
                    </Alert>
                )}
                <Row className="om-header-row">
                    <Col md={4} className="mb-3">
                        <Form onSubmit={handleSearch} className="om-search-form">
                            <div className="om-search-wrapper">
                                <Form.Control
                                    type="text"
                                    value={orderIdInput}
                                    onChange={(e) => setOrderIdInput(e.target.value)}
                                    placeholder="Enter Order ID"
                                    className="om-input"
                                />
                                <Button
                                    type="submit"
                                    className="om-search-btn"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <Spinner animation="border" size="sm"/>
                                    ) : (
                                        'Search'
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>

                {searchError && (
                    <Row>
                        <Col>
                            <Alert variant="danger" className="om-alert">
                                {searchError}
                            </Alert>
                        </Col>
                    </Row>
                )}

                {order && (
                    <div className="om-order-content">
                        <Row className="mb-4">
                            <Col md={6}>
                                <Card className="om-order-details">
                                    <Card.Header className="om-card-header">
                                        <Card.Title>Order Details</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="om-card-body">
                                        <Table className="om-details-table" borderless>
                                            <tbody>
                                            <tr>
                                                <td className="om-details-label">Order ID:</td>
                                                <td className="om-details-value">{order.orderId}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Project Name:</td>
                                                <td className="om-details-value">{order.projectName}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Purchase Order:</td>
                                                <td className="om-details-value">{order.purchaseOrder}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Status:</td>
                                                <td className="om-details-value">{order.status}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Date:</td>
                                                <td className="om-details-value">{new Date(order.date).toLocaleDateString()}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="om-special-instructions">
                                    <Card.Header className="om-card-header">
                                        <Card.Title>Special Instructions</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="om-card-body">
                                        <p className="om-instructions-text">
                                            {order.specialInstructions || 'No special instructions provided'}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group className="om-filter-group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Filter by Position Name"
                                        value={searchFilter}
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                        className="om-filter-input"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="text-end">
                                <Button
                                    onClick={handleSaveChanges}
                                    disabled={saving}
                                    className="om-save-btn"
                                    variant="dark"
                                >
                                    {saving ? (
                                        <>
                                            <Spinner animation="border" size="sm" className="me-2"/>
                                            Saving Changes...
                                        </>
                                    ) : (
                                        'Save Changes'
                                    )}
                                </Button>
                            </Col>
                        </Row>

                        <Row className="om-articles-section">
                            <Col>
                                <Card className="om-table-card">
                                    <Card.Header className="om-card-header">
                                        <Nav variant="tabs" className="om-nav-tabs">
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'materials' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('materials')}
                                                >
                                                    Materials
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'edgebanding' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('edgebanding')}
                                                >
                                                    Edgebanding
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>
                                    <Card.Body className="om-table-wrapper">
                                        {activeTab === 'materials' ? renderMaterialsTable() : renderEdgebandingTable()}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}

                <Modal show={showNewMaterialModal} onHide={() => setShowNewMaterialModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Material</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Imos Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMaterial.name}
                                    onChange={(e) => setNewMaterial({...newMaterial, name: e.target.value})}
                                    isInvalid={!!materialValidation.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {materialValidation.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newMaterial.description}
                                    onChange={(e) => setNewMaterial({...newMaterial, description: e.target.value})}
                                    isInvalid={!!materialValidation.description}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {materialValidation.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Thickness (mm)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={newMaterial.thickness}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === '' || /^\d*\.?\d*$/.test(value)) {
                                            setNewMaterial({...newMaterial, thickness: value});
                                        }
                                    }}
                                    isInvalid={!!materialValidation.thickness}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {materialValidation.thickness}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Raw Cost</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={newMaterial.rawCost}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === '' || /^\d*\.?\d*$/.test(value)) {
                                            setNewMaterial({...newMaterial, rawCost: value});
                                        }
                                    }}
                                    isInvalid={!!materialValidation.rawCost}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {materialValidation.rawCost}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Visible</Form.Label>
                                <Form.Select
                                    value={newMaterial.visible}
                                    onChange={(e) => setNewMaterial({...newMaterial, visible: e.target.value})}
                                >
                                    <option value="1">1</option>
                                    <option value="0">0</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowNewMaterialModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="btn btn-dark" onClick={handleNewMaterialSubmit}>
                            Create Material
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showNewEdgebandingModal} onHide={() => setShowNewEdgebandingModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Edgebanding</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Imos Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newEdgebanding.imosName}
                                    onChange={(e) => setNewEdgebanding({...newEdgebanding, imosName: e.target.value})}
                                    isInvalid={!!edgebandingValidation.imosName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {edgebandingValidation.imosName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newEdgebanding.englishDescription}
                                    onChange={(e) => setNewEdgebanding({
                                        ...newEdgebanding,
                                        englishDescription: e.target.value
                                    })}
                                    isInvalid={!!edgebandingValidation.englishDescription}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {edgebandingValidation.englishDescription}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Visible</Form.Label>
                                <Form.Select
                                    value={newEdgebanding.visible}
                                    onChange={(e) => setNewEdgebanding({...newEdgebanding, visible: e.target.value})}
                                >
                                    <option value="1">1</option>
                                    <option value="0">0</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowNewEdgebandingModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="btn btn-dark" onClick={handleNewEdgebandingSubmit}>
                            Create Edgebanding
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    );
};

export default OrderManagementComponent;