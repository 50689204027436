import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    settings: {
        caseMaterial: '3/4" Pre-finished Maple',
        frontMaterial: '3/4" Medex',
        backPanelMaterial: '3/4" Pre-finished Maple',
        caseEdge: '0.5 millimeter Edgebanding',
        frontEdge: '0.5 millimeter Edgebanding',
        backPanel: 'Applied',
        jointMethod: 'Biscuit-Screw',
        hingePlate: 'Cross',
        drawerType: 'Wooden 5/8"'


    }
};

const globalSettingsSlice = createSlice({
    name: 'globalSettings',
    initialState,
    reducers: {
        updateGlobalSettings: (state, action) => {
            state.settings = {...state.settings, ...action.payload};
        },
        resetGlobalSettings: (state) => {
            state.settings = initialState.settings;
        },
    },
});

export const selectGlobalSettings = (state) => state.globalSettings.settings;

export const {
    updateGlobalSettings,
    resetGlobalSettings,
} = globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;