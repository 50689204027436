import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { logOut, storeUser } from '../features/user/userSlice'; // Adjust the import path as per your project structure
import LoginModal from '../modals/LoginModal'; // Adjust the import path as necessary

const isTokenExpired = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Failed to decode token:', error);
        return true;
    }
};

const AuthCheck = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.user.accessToken);
    const [showLoginModal, setShowLoginModal] = useState(false);

    useEffect(() => {
        if (token && isTokenExpired(token)) {
            dispatch(logOut());
            setShowLoginModal(true);
        }
    }, [token, dispatch]);

    const handleCloseLoginModal = () => {
        setShowLoginModal(false);
        navigate('/'); // Redirect to the home page or another appropriate page after login modal closes
    };

    const handleRegisterOpen = () => {
        setShowLoginModal(false);
        navigate('/register'); // Redirect to the register page
    };

    return (
        <>
            {children}
            <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} handleRegisterOpen={handleRegisterOpen} />
        </>
    );
};

export default AuthCheck;
