import React from 'react';
import jsPDF from 'jspdf';
import sealabIcon from '../../images/sealab_icon.png'; // Adjust the path if necessary

const PdfWriterComponent = ({orderDetails, imageURL}) => {
    const generatePDF = async () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4',
        });

        const loadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
                img.src = url;
            });
        };

        // Load the company logo
        const logo = await loadImage(sealabIcon);

        // Add SEALAB header with logo
        pdf.addImage(logo, 'PNG', 40, 30, 50, 50); // Adjust size and position as needed
        pdf.setFontSize(22);
        pdf.text("SEALAB", 500, 50, {align: 'right'}); // Company name to the right
        pdf.setFontSize(10);
        pdf.text("63 Flushing Ave,\nBuilding 3, Suite 1108\nBrooklyn, NY 11205", 500, 70, {align: 'right'}); // Company info to the right
        pdf.setLineWidth(0.5);
        pdf.line(40, 100, 570, 100); // Horizontal line below the header

        let currentY = 120; // Starting Y position for order details
        let itemsOnPage = 0; // Counter for items per page

        for (const order of orderDetails) {
            if (itemsOnPage >= 2 || currentY > 700) {
                // Add a new page if there are 2 items already or if the current Y position is too low
                pdf.addPage();
                currentY = 40; // Reset Y position for new page
                itemsOnPage = 0; // Reset items counter
            }

            pdf.setFontSize(12);

            const details = [
                `Item Name: ${order.positionName}`,
                `Serial Number: ${order.serialNumber}`,
                `Height: ${order.height} in`,
                `Width: ${order.width} in`,
                `Depth: ${order.depth} in`,
                `Case Material: ${order.caseMaterial}`,
                `Front Material: ${order.frontMaterial}`,
                order.drawerType !== 'false' ? `Drawer Type: ${order.drawerType}` : '',
                order.gapTop !== 'false' ? `Gap Top: ${order.gapTop}` : '',
                order.gapBottom !== 'false' ? `Gap Bottom: ${order.gapBottom}` : '',
                order.gapLeft !== 'false' ? `Gap Left: ${order.gapLeft}` : '',
                order.gapRight !== 'false' ? `Gap Right: ${order.gapRight}` : '',
                order.gapCenter !== 'false' ? `Gap Center: ${order.gapCenter}` : '',
                order.hingeSide !== 'false' && order.hingeSide != null ? `Hinge Side: ${order.hingeSide}` : '',
                order.jointMethod !== 'false' ? `Joint Method: ${order.jointMethod}` : '',
            ];

            details.forEach((detail) => {
                if (detail) {
                    pdf.text(detail, 40, currentY); // Print order details
                    currentY += 15; // Adjusted spacing
                }
            });

            try {
                const img = await loadImage(`${imageURL}${order.serialNumber}.png`);
                const imgNF = await loadImage(`${imageURL}${order.serialNumber}_NF.png`);
                pdf.addImage(img, 'PNG', 320, currentY - (15 * details.length) + 20, 125, 125); // Add first product image
                pdf.addImage(imgNF, 'PNG', 450, currentY - (15 * details.length) + 20, 125, 125); // Add second product image

                pdf.setFontSize(8);
                pdf.text("Please note, these images are not reflective of configured cabinet dimensions.", 320, currentY - (15 * details.length) + 150, {maxWidth: 260});
            } catch (error) {
                console.error('Error loading image:', error);
            }

            currentY += 50; // Adjust space after each item
            itemsOnPage++;
            if (itemsOnPage < 2 && order !== orderDetails[orderDetails.length - 1]) {
                currentY += 10; // Extra spacing before the horizontal line
                pdf.line(40, currentY - 10, 570, currentY - 10); // Line separator
                currentY += 10; // Additional spacing after the horizontal line
            }
        }

        pdf.save('order-details.pdf');
    };

    return (
        <button onClick={generatePDF} className="btn btn-dark">
            Print Full Summary to PDF
        </button>
    );
};

export default PdfWriterComponent;
