import React, {useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import PasswordResetService from "../api/PasswordResetService";

const ForgotPasswordModal = ({show, handleClose}) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (!show) {
            setEmail('');
            setMessage('');
            setError('');
        }
    }, [show]);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await PasswordResetService.requestPasswordReset(email);
            setMessage(response.data.message);
            setError('');

            if (response.data.error)
                setError(response.data.error)

        } catch (error) {
            console.error('Password reset request failed:', error);
            setMessage('');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{color: 'black'}}>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="email">
                        <Form.Label style={{color: 'black'}}>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={handleChange} value={email}/>
                    </Form.Group>
                </Form>
                {message && <p className="text-success">{message}</p>}
                {error && <p className="text-danger">{error}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} style={{backgroundColor: 'black', color: 'white'}}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ForgotPasswordModal;