// OrderAgreementModal.js
import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

const OrderAgreementModal = ({show, onHide, onAgree}) => {
    const [understandAssembly, setUnderstandAssembly] = useState(false);
    const [readDeliveryInfo, setReadDeliveryInfo] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleAgree = () => {
        if (understandAssembly && readDeliveryInfo) {
            onAgree();
        } else {
            setShowError(true);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton className="bg-white text-dark">
                <Modal.Title>IMPORTANT: Order Delivery Notice</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-white text-dark">
                <p>Please read carefully before completing your purchase:</p>
                <Form.Check
                    type="checkbox"
                    id="assembly-checkbox"
                    label="I understand that if I do not select the assembly option for my order:"
                    checked={understandAssembly}
                    onChange={(e) => setUnderstandAssembly(e.target.checked)}
                    className="mb-2"
                />
                <ol>
                    <li>My order will be delivered as individual, unassembled cut parts directly from our manufacturing
                        partner.
                    </li>
                    <li>These parts will not be pre-assembled into cabinets or furniture units.</li>
                    <li>I will be responsible for assembling the parts into the final product.</li>
                </ol>
                <p>By proceeding with this order without selecting assembly, I acknowledge that I have the necessary
                    skills and tools to assemble the product, or that I will arrange for professional assembly
                    separately.</p>
                <p>Sealab is not responsible for any issues arising from improper assembly of loose parts. If you are
                    unsure about your ability to assemble the product, we strongly recommend selecting our professional
                    assembly option.</p>
                <Form.Check
                    type="checkbox"
                    id="delivery-info-checkbox"
                    label="I have read and understand the above information regarding the delivery of unassembled parts"
                    checked={readDeliveryInfo}
                    onChange={(e) => setReadDeliveryInfo(e.target.checked)}
                    className="mt-3"
                />
                {showError && (
                    <Alert variant="danger" className="mt-3">
                        Please agree to both terms before proceeding.
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className="bg-white">
                <Button variant="dark" onClick={handleAgree}>
                    I Agree
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderAgreementModal;