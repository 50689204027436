import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editUser} from '../../features/user/userSlice';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import './EditProfileStyles.css';
import ProfileService from '../../api/ProfileService';
import {Helmet} from 'react-helmet-async'

const EditProfileComponent = () => {
    const {user, accessToken} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({
        email: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        apartment: '',
        city: '',
        state: '',
        zipcode: '',
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    useEffect(() => {
        if (user) {
            const {email, phoneNumber, address1, address2, apartment, city, state, zipcode} = user;
            setUserInfo({
                email: email || '',
                phoneNumber: phoneNumber || '',
                address1: address1 || '',
                address2: address2 || '',
                apartment: apartment || '',
                city: city || '',
                state: state || '',
                zipcode: zipcode || '',
            });
        }
    }, [user]);

    const handleChange = e => {
        const {name, value} = e.target;

        if (name === 'zipcode') {
            const formattedZipcode = value.replace(/[^0-9]/g, '').slice(0, 5);
            setUserInfo(prevState => ({...prevState, [name]: formattedZipcode}));
            return;
        }

        if (name === 'phoneNumber') {
            const formattedPhoneNumber = value.replace(/[^0-9]/g, '').slice(0, 10);
            const phoneWithDashes = formattedPhoneNumber
                .replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3')
                .slice(0, 12);
            setUserInfo(prevState => ({...prevState, [name]: phoneWithDashes}));
            return;
        }

        setUserInfo(prevState => ({...prevState, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedUserInfo = {};
        Object.entries(userInfo).forEach(([key, value]) => {
            if (value !== user[key]) {
                updatedUserInfo[key] = value;
            }
        });
        try {
            const response = await ProfileService.editProfile({...user, ...updatedUserInfo}, accessToken);
            setAlertMessage(response.data);
            setAlertType('success');
            setShowAlert(true);
            dispatch(editUser({user: {...user, ...updatedUserInfo}, accessToken}));
        } catch (error) {
            console.error('Error updating profile:', error);
            setAlertMessage('An error occurred while updating the profile.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    const Navbar = user ? NavbarLoggedInComponent : NavbarComponent;

    const formatLabel = (label) => {
        switch (label) {
            case 'phoneNumber':
                return 'Phone Number';
            case 'address1':
                return 'Address 1';
            case 'address2':
                return 'Address 2';
            default:
                return label.charAt(0).toUpperCase() + label.slice(1);
        }
    };

    return (
        <>
            <Navbar/>
            <div className="edit-profile-page">
                <Helmet>
                    <title>Edit Profile | The Sealab - Premium Kitchen & Closet Cabinets</title>
                    <meta name="description"
                          content="Update your profile information on The Sealab. Ensure your details are accurate to receive the best service and personalized recommendations for premium kitchen and closet cabinets."/>
                    <meta name="keywords"
                          content="edit profile, update profile, The Sealab, kitchen cabinets, closet cabinets, profile information, personalized recommendations"/>
                    <meta property="og:title" content="Edit Profile | The Sealab - Premium Kitchen & Closet Cabinets"/>
                    <meta property="og:description"
                          content="Update your profile information on The Sealab. Ensure your details are accurate to receive the best service and personalized recommendations for premium kitchen and closet cabinets."/>
                    <meta property="og:url" content="https://www.thesealab.com/edit-profile"/>
                    <meta property="og:type" content="website"/>
                </Helmet>

                <div className="edit-profile-container">
                    <h1 className="edit-profile-title">Edit Your Profile</h1>
                    <form onSubmit={handleSubmit} className="edit-profile-form">
                        <div className="form-section">
                            <h2 className="section-title">Contact Information</h2>
                            <div className="form-grid">
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={userInfo?.email}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={(userInfo?.phoneNumber !== 'null' && userInfo.phoneNumber !== null) ? userInfo?.phoneNumber : ''}
                                        onChange={handleChange}
                                        className="form-input"
                                        maxLength={12}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-section">
                            <h2 className="section-title">Address Information</h2>
                            <div className="form-grid">
                                <div className="form-group">
                                    <label htmlFor="address1" className="form-label">Address 1</label>
                                    <input
                                        type="text"
                                        id="address1"
                                        name="address1"
                                        value={(userInfo?.address1 !== 'null' && userInfo?.address1 !== null) ? userInfo?.address1 : ''}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address2" className="form-label">Address 2</label>
                                    <input
                                        type="text"
                                        id="address2"
                                        name="address2"
                                        value={(userInfo?.address2 !== 'null' && userInfo?.address2 !== null) ? userInfo?.address2 : ''}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="apartment" className="form-label">Apartment</label>
                                    <input
                                        type="text"
                                        id="apartment"
                                        name="apartment"
                                        value={(userInfo?.apartment !== 'null' && userInfo?.apartment !== null) ? userInfo?.apartment : ''}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={(userInfo?.city !== 'null' && userInfo?.city !== null) ? userInfo?.city : ''}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <input
                                        type="text"
                                        id="state"
                                        name="state"
                                        value={(userInfo?.state !== 'null' && userInfo?.state !== null) ? userInfo?.state : ''}
                                        onChange={handleChange}
                                        maxLength={2}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="zipcode" className="form-label">Zipcode</label>
                                    <input
                                        type="text"
                                        id="zipcode"
                                        name="zipcode"
                                        value={(userInfo?.zipcode !== 'null' && userInfo?.zipcode !== null) ? userInfo?.zipcode : ''}
                                        onChange={handleChange}
                                        maxLength={5}
                                        className="form-input"
                                        pattern="\d{5}"
                                        title="Five digit zip code"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-actions">
                            <button type="submit" className="save-btn">Save Changes</button>
                        </div>
                    </form>
                </div>
                {showAlert && (
                    <div className={`alert ${alertType === 'success' ? 'alert-success' : 'alert-error'}`}>
                        <p className="alert-message">{alertMessage}</p>
                        <button className="alert-close" onClick={closeAlert}>&times;</button>
                    </div>
                )}
            </div>
        </>
    );
};

export default EditProfileComponent;