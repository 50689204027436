import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FooterStyles.css';

const Footer = () => {
    return (
        <footer className="footer bg-light py-3 mt-auto">
            <div className="container text-center">
                <Link to="/the-sealab/legal/privacy" className="footer-link">Privacy Policy</Link>
                <span className="mx-2">|</span>
                <Link to="/the-sealab/legal/terms" className="footer-link">Terms of Service</Link>
            </div>
        </footer>
    );
};

export default Footer;
