import {createSlice, nanoid} from '@reduxjs/toolkit';

const initialState = {
    cart: [],
    totalQuantity: 0,
    orderNumber: nanoid(),
    totalPrice: 0.0,
    uniqueItemId: nanoid(),
    selectedItems: [],
    foundItem: {},
    paymentSuccess: false,
    isRevisedOrder: false,
    revisedOrderInfo: {},
    positionCounter: 1,
    reservedPositionNames: [],
    cabinetsWithCoordinates: [],
    highestPositionNumbers: {}, // Initialize as empty object
    specialInstructions: "",
};

// Helper function to generate a position name based on counter and prefix
const generatePositionName = (counter, prefix = 'POS') => {
    return `${prefix}_${counter.toString().padStart(3, '0')}`;
};

// Helper function to extract prefix and number from position name
const parsePositionName = (positionName) => {
    const [prefix, number] = positionName.split('_');
    return {
        prefix,
        number: parseInt(number)
    };
};

// Helper function to get the next available position name
const getNextUniquePositionName = (state, prefix = 'POS') => {
    // Initialize highestPositionNumbers if it doesn't exist
    if (!state.highestPositionNumbers) {
        state.highestPositionNumbers = {};
    }

    let counter = state.highestPositionNumbers[prefix] || 1;
    let positionName;

    do {
        positionName = generatePositionName(counter, prefix);
        counter++;
    } while (state.reservedPositionNames?.includes(positionName));

    state.highestPositionNumbers[prefix] = counter;
    state.reservedPositionNames?.push(positionName);
    state.reservedPositionNames?.sort();
    return positionName;
};

// Helper function to update position names after a specific position
const updatePositionNamesAfter = (state, referencePositionName) => {
    const refParsed = parsePositionName(referencePositionName);

    // Initialize highestPositionNumbers if it doesn't exist
    if (!state.highestPositionNumbers) {
        state.highestPositionNumbers = {};
    }

    // Find all items that need their position names updated
    const itemsToUpdate = state.cart
        .filter(item => {
            const itemParsed = parsePositionName(item.positionName);
            return itemParsed.prefix === refParsed.prefix &&
                itemParsed.number > refParsed.number;
        })
        .sort((a, b) => {
            const aNum = parsePositionName(a.positionName).number;
            const bNum = parsePositionName(b.positionName).number;
            return aNum - bNum;
        });

    // Update position names for affected items
    itemsToUpdate.forEach(item => {
        const itemParsed = parsePositionName(item.positionName);
        const newPositionName = generatePositionName(itemParsed.number + 1, itemParsed.prefix);

        const cartItem = state.cart.find(i => i.itemId === item.itemId);
        if (cartItem) {
            // Remove old position name
            state.reservedPositionNames = state.reservedPositionNames.filter(
                name => name !== cartItem.positionName
            );

            // Update to new position name
            cartItem.positionName = newPositionName;

            // Add new position name
            state.reservedPositionNames.push(newPositionName);
        }
    });

    // Sort reservedPositionNames
    state.reservedPositionNames.sort();

    // Update highest number for prefix
    if (itemsToUpdate.length > 0) {
        const maxNum = Math.max(...itemsToUpdate.map(item =>
            parsePositionName(item.positionName).number
        ));
        state.highestPositionNumbers[refParsed.prefix] = maxNum + 1;
    }
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            if (action.payload) {
                if (action.payload.item) {
                    const itemWithLegLevelers = {
                        ...action.payload.item,
                        includeLegLevelers: action.payload?.filterTags?.includes('Leg_Levelers') || false,
                    };
                    state.cart.push(itemWithLegLevelers);
                    state.totalQuantity += parseInt(action.payload.quantity, 10);
                    console.log('qwieruj')
                } else {
                    // Ensure highestPositionNumbers exists
                    if (!state.highestPositionNumbers) {
                        state.highestPositionNumbers = {};
                    }
                    console.log('ll: ' + action.payload.includeLegLevelers)

                    const positionName = action.payload?.positionName || getNextUniquePositionName(state);

                    const item = {
                        itemId: action?.payload.id,
                        backPanel: action.payload?.backPanel || 'Inset',
                        serialNumber: action.payload?.serialNumber || 'test',
                        drawerType: action.payload?.drawers === '0' ? false : action.payload?.drawerType || 'Wooden 5/8"',
                        gapTop: action.payload?.gapControl === '0' ? false : action.payload?.gapTop || '0.125',
                        gapBottom: action.payload?.gapControl === '0' ? false : action.payload?.gapBottom || '0.125',
                        gapLeft: action.payload?.gapControl === '0' ? false : action.payload?.gapLeft || '0.125',
                        gapRight: action.payload?.gapControl === '0' ? false : action.payload?.gapRight || '0.125',
                        gapCenter: (1 / 8).toFixed(3),
                        jointControl: action.payload?.jointControl,
                        jointMethod: action.payload?.jointControl === '0' ? false : action.payload?.jointMethod || 'Biscuit',
                        heightRange: action.payload?.heightRange,
                        widthRange: action.payload?.widthRange,
                        depthRange: action.payload?.depthRange,
                        frontMaterial: action.payload?.frontMaterial || '3/4" Medex',
                        caseMaterial: action.payload?.caseMaterial || '3/4" Pre-finished Maple',
                        backPanelMaterial: action.payload?.backPanelMaterial || action.payload?.caseMaterial || '3/4" Pre-finished Maple',
                        quantity: action.payload.quantity || 1,
                        height: action.payload?.height || action.payload?.heightRange?.split('-')[0],
                        width: action.payload?.width || action.payload?.widthRange?.split('-')[0],
                        depth: action.payload?.depth || action.payload?.depthRange?.split('-')[0],
                        positionName,
                        caseEdge: action.payload?.caseEdge,
                        frontEdge: action.payload?.frontEdge,
                        edge1: action.payload?.edgeBandingBottom === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edge2: action.payload?.edgeBandingRight === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edge3: action.payload?.edgeBandingTop === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edge4: action.payload?.edgeBandingLeft === 'true' ? action.payload.edgeBandingType : 'No Edgebanding',
                        edgeBandingTop: action.payload?.edgeBandingTop || 'false',
                        edgeBandingBottom: action.payload?.edgeBandingBottom,
                        edgeBandingRight: action.payload?.edgeBandingRight,
                        edgeBandingLeft: action.payload?.edgeBandingLeft,
                        edgeBandingType: action.payload?.edgeBandingType || 'No Edgebanding',
                        description: action.payload?.description,
                        drawers: action.payload?.drawers,
                        doors: action.payload?.doors,
                        topDrwrHeight: action.payload?.topDrwrHeight || '1',
                        topDrwrHeightValue: action.payload?.topDrwrHeight === '1' ? action.payload?.topDrwrHeightValue : '6',
                        adjShelves: action.payload?.adjShelves,
                        numOfShelves: action.payload?.numOfShelves || 'Parametric Shelves',
                        includeLegLevelers: action.payload?.includeLegLevelers || 'false',
                        bottomPanelConnector: action.payload?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
                        hingePlate: action.payload?.doors === 1 && action.payload?.hingePlate !== undefined ? action.payload?.hingePlate : 'Cross',
                        excludeFronts: action.payload?.excludeFronts || false,
                        matBack: action.payload?.matBack,
                        legLevelers: action.payload?.legLevelers,
                        gapControl: action.payload?.gapControl,
                        filterTags: action.payload?.filterTags,
                        globalSettingsApplied: action.payload?.globalSettingsApplied || false,
                        leftCornerWidth: action.payload?.leftCornerWidth === 0 ? 1.5 : 1.5,
                        rightCornerDepth: action.payload?.rightCornerDepth === 0 ? 1.5 : 1.5,
                        groupId: action.payload?.quantity > 1 ? nanoid() : null,
                    };

                    state.cart.push(item);
                    state.totalQuantity += parseInt(action.payload.quantity, 10);

                    // Update highestPositionNumbers for the prefix
                    const {prefix, number} = parsePositionName(positionName);
                    state.highestPositionNumbers[prefix] = Math.max(
                        state.highestPositionNumbers[prefix] || 0,
                        number + 1
                    );

                    if (action.payload?.quantity > 1) {
                        for (let i = 1; i < action.payload?.quantity; i++) {
                            getNextUniquePositionName(state)
                        }
                    }

                }
            }
        },

        reAddToCart: (state, action) => {
            // Ensure highestPositionNumbers exists
            if (!state.highestPositionNumbers) {
                state.highestPositionNumbers = {};
            }

            // const positionName = action.payload.positionName || getNextUniquePositionName(state);
            const positionName = getNextUniquePositionName(state);
            const {prefix, number} = parsePositionName(positionName);

            state.cart.push({
                ...action.payload,
                positionName,
                itemId: action.payload.itemId,
                quantity: Number(action.payload.quantity),
                excludeFronts: action.payload?.excludeFronts !== 'false' && action.payload?.excludeFronts !== false,

            });

            state.totalQuantity += parseInt(action.payload.quantity, 10);

            if (action.payload?.quantity > 1) {
                for (let i = 1; i < action.payload.quantity; i++) {
                    getNextUniquePositionName(state);
                }
            }

            // Update highestPositionNumbers
            state.highestPositionNumbers[prefix] = Math.max(
                state.highestPositionNumbers[prefix] || 0,
                number + 1
            );
        },

        incrementQuantity: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
            if (itemIndex >= 0) {
                // Ensure highestPositionNumbers exists
                if (!state.highestPositionNumbers) {
                    state.highestPositionNumbers = {};
                }

                const item = state.cart[itemIndex];
                const {prefix, number} = parsePositionName(item.positionName);

                // Increment quantity
                item.quantity += 1;
                state.totalQuantity += 1;

                // Update position names for higher numbered items
                updatePositionNamesAfter(state, item.positionName);

                // Update highest number for this prefix
                state.highestPositionNumbers[prefix] = Math.max(
                    state.highestPositionNumbers[prefix] || 0,
                    number + item.quantity
                );

                if (item.quantity > 1 && item.groupId === null) {
                    item.groupId = nanoid();
                }
            }
        },

        decrementQuantity: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
            if (itemIndex >= 0 && state.cart[itemIndex].quantity > 1) {
                // Ensure highestPositionNumbers exists
                if (!state.highestPositionNumbers) {
                    state.highestPositionNumbers = {};
                }

                const item = state.cart[itemIndex];
                const {prefix, number} = parsePositionName(item.positionName);

                // Calculate position name to remove
                const positionToRemove = generatePositionName(number + item.quantity - 1, prefix);

                // Decrement quantity
                item.quantity -= 1;
                state.totalQuantity -= 1;

                // Remove position name from reserved list
                state.reservedPositionNames = state.reservedPositionNames.filter(
                    name => name !== positionToRemove
                );

                // Update highest number for this prefix
                const highestRemaining = Math.max(
                    ...state.reservedPositionNames
                        .filter(name => parsePositionName(name).prefix === prefix)
                        .map(name => parsePositionName(name).number)
                );

                state.highestPositionNumbers[prefix] = highestRemaining + 1;

                if (item.quantity === 1) {
                    item.groupId = null;
                }
            }
        },

        removeItem: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload.id);
            if (itemIndex >= 0) {
                const removedItem = state.cart[itemIndex];
                const {prefix} = parsePositionName(removedItem.positionName);

                // Update total quantity
                state.totalQuantity -= parseInt(removedItem.quantity, 10);

                // Remove the item's position name from reservedPositionNames
                state.reservedPositionNames = state.reservedPositionNames.filter(
                    name => name !== removedItem.positionName
                );
                console.log('test')
                console.log('reservedPositionNames: ', state.reservedPositionNames);

                // Remove the item
                state.cart.splice(itemIndex, 1);

                // Update highestPositionNumbers if needed
                if (state.cart.length > 0) {
                    const remainingWithPrefix = state.cart.filter(
                        item => parsePositionName(item.positionName).prefix === prefix
                    );

                    if (remainingWithPrefix.length > 0) {
                        const maxNum = Math.max(
                            ...remainingWithPrefix.map(
                                item => parsePositionName(item.positionName).number
                            )
                        );
                        state.highestPositionNumbers[prefix] = maxNum + 1;
                    } else {
                        delete state.highestPositionNumbers[prefix];
                    }
                }

                if (state.totalQuantity <= 0) {
                    state.totalQuantity = 0;
                    state.isRevisedOrder = false;
                    state.revisedOrderInfo = {};
                    state.highestPositionNumbers = {};
                }
            }
        },

        emptyCart: (state) => {
            state.cart = [];
            state.totalQuantity = 0;
            state.orderNumber = nanoid();
            state.isRevisedOrder = false;
            state.revisedOrderInfo = {};
            state.positionCounter = 1;
            state.reservedPositionNames = [];
            state.cabinetsWithCoordinates = [];
            state.highestPositionNumbers = {};
            state.specialInstructions = '';
            localStorage.removeItem('savedCabinets');
        },

        updateItemsInCart: (state, action) => {
            const updatedItems = action.payload;
            updatedItems.forEach((updatedItem) => {
                const itemIndex = state.cart.findIndex((item) => item.itemId === updatedItem.itemId);

                if (itemIndex >= 0) {
                    state.cart[itemIndex] = {...state.cart[itemIndex], ...updatedItem};
                }
            });
        },

        updateCabinetsFromDrawingTool: (state, action) => {
            const cabinets = action?.payload;
            let cabinetArray = [];

            cabinets.map((cabinet) => {
                let item = {
                    positionName: cabinet?.positionName,
                    x: cabinet?.x,
                    y: cabinet?.y,
                    z: cabinet?.z,
                    rotation: cabinet?.rotation
                }
                cabinetArray.push(item);
            })

            console.log(cabinetArray)
            state.cabinetsWithCoordinates = cabinetArray;
        },

        paymentSucceeded: (state, action) => {
            state.paymentSuccess = action.payload;
        },

        findItemById: (state, action) => {
            const itemIndex = state.cart.findIndex((item) => item.itemId === action.payload);
            state.foundItem = state.cart[itemIndex] ? {...state.cart[itemIndex]} : {};
        },

        setIsRevisedOrder: (state, action) => {
            state.isRevisedOrder = action.payload;
        },

        setOrderInfo: (state, action) => {
            state.revisedOrderInfo = action.payload;
        },

        clearRevisedOrder: (state) => {
            state.isRevisedOrder = false;
            state.revisedOrderInfo = {};
        },
        addSpecialInstructions: (state, action) => {
            state.specialInstructions = action.payload;
        },
    },
});

export const selectCart = (state) => state.cart;

export const {
    addToCart,
    reAddToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    emptyCart,
    updateItemsInCart,
    paymentSucceeded,
    findItemById,
    setIsRevisedOrder,
    setOrderInfo,
    clearRevisedOrder,
    updateCabinetsFromDrawingTool,
    addSpecialInstructions,
} = cartSlice.actions;

export default cartSlice.reducer;