import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

function LoginService(credentials) {
    return axios.post(`${baseURL}/login`, {
        email: credentials.email,
        password: credentials.password
    });
}

export default LoginService;
