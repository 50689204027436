import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PasswordResetService from "../api/PasswordResetService";

const ResetPasswordModal = ({ show, handleClose, token }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'newPassword') {
            setNewPassword(value);
            validatePassword(value);
            validateConfirmPassword(confirmPassword); // Revalidate confirm password when new password changes
        } else if (id === 'confirmPassword') {
            setConfirmPassword(value);
            validateConfirmPassword(value);
        }
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (!regex.test(password)) {
            setPasswordError('Password must be at least 8 characters long, contain one uppercase letter and one number.');
        } else {
            setPasswordError('');
        }
    };

    const validateConfirmPassword = (password) => {
        if (password !== newPassword) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSubmit = async () => {
        if (passwordError || confirmPasswordError) {
            setMessage('Please fix the errors before submitting.');
            return;
        }

        try {
            const response = await PasswordResetService.resetPassword(token, newPassword);
            setMessage(response.data.message);
        } catch (error) {
            console.error('Password reset failed:', error);
            setMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: 'black' }}>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="newPassword">
                        <Form.Label style={{ color: 'black' }}>New Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter new password" value={newPassword} onChange={handleChange} />
                        {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                        <Form.Label style={{ color: 'black' }}>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm new password" value={confirmPassword} onChange={handleChange} />
                        {confirmPasswordError && <p style={{ color: 'red' }}>{confirmPasswordError}</p>}
                    </Form.Group>
                </Form>
                {message && <p style={{ color: passwordError || confirmPasswordError ? 'red' : 'black' }}>{message}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} style={{ backgroundColor: 'black', color: 'white' }}>
                    Reset Password
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResetPasswordModal;
