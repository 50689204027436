import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

const RegisterService = async (user) => {
    try {
        return await axios.post(`${baseURL}/register`, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
        });

    } catch (error) {
        console.error('Error registering user:', error);
        throw error;
    }
};

export default RegisterService;
