import React from 'react';

function ImageComponent() {
    // Assuming 'BAS_1000' is the ID you want to fetch
    // Update the endpoint as per your Spring Boot controller's @GetMapping
    const imageId = 'BAS_1000';
    const imageUrl = `http://localhost:8080/testing/${imageId}`;

    return (
        <div>
            <h1>Image Display</h1>
            <img src={imageUrl} alt="Display" />
        </div>
    );
}

export default ImageComponent;
