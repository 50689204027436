import React, {useEffect, useState} from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {FaShoppingCart} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import LoginModal from "../../modals/LoginModal";
import RegisterModal from "../../modals/RegisterModal";
import './NavbarStyles.css';
import {logOut} from "../../features/user/userSlice";
import {useNavigate} from "react-router-dom";

const NavbarLoggedInComponent = () => {
    const cartQuantity = useSelector(state => state.cart.totalQuantity);
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (cart.cart?.length < 1 && cart.totalQuantity > 0) {
            cart.totalQuantity = 0;
        }
    }, [cart.cart?.length, cart.totalQuantity]);

    const openRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    };

    const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);

    const truncateEmail = (email) => {
        const truncated = email.substring(0, email.indexOf('@'));
        return truncated.charAt(0).toUpperCase() + truncated.slice(1);
    };

    const handleLogout = () => {
        dispatch(logOut());
        navigate('/');
    };

    return (
        <div className="">
            <Navbar collapseOnSelect expand="lg" className="custom-navbar" variant="dark">
                <Navbar.Brand href="/">THE SEALAB</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/* Left-aligned items (if any) */}
                    </Nav>
                    <Nav className="mx-auto">
                        <Nav.Link href="/showroom">Catalog</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <NavDropdown title={capitalizeFirstLetter(user?.firstName)} id="account-dropdown"
                                     className="custom-dropdown">
                            {user && user.email ? (
                                <>
                                    <NavDropdown.Item href="/user/profile">My Profile</NavDropdown.Item>
                                    <NavDropdown.Item href="/user/customer-portal">Customer Portal</NavDropdown.Item>
                                    <NavDropdown.Item href="/user/profile/saved-orders">Saved Orders</NavDropdown.Item>
                                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                </>
                            ) : (
                                <NavDropdown.Item onClick={() => setShowLogin(true)}>Login</NavDropdown.Item>
                            )}
                        </NavDropdown>
                        <Nav.Link href="/customer-support/contact">Contact</Nav.Link>
                        <Nav.Link href="/the-sealab/about-us">About Us</Nav.Link>
                        <Nav.Link href="/cart" className="nav-cart-link">
                            <div className="nav-cart-icon">
                                <FaShoppingCart size={24} color="white"/>
                                {cartQuantity > 0 && (
                                    <span className="cart-quantity-badge">{cartQuantity}</span>
                                )}
                            </div>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <LoginModal
                show={showLogin}
                handleClose={() => setShowLogin(false)}
                handleRegisterOpen={openRegister}
            />
            <RegisterModal
                show={showRegister}
                handleClose={() => setShowRegister(false)}
            />
        </div>
    );
};

export default NavbarLoggedInComponent;