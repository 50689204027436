// ProfileService.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

const ProfileService = {
    editProfile: async (user, accessToken) => {
        try {
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            };

            const body = {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                address_1: user.address1,
                address_2: user.address2,
                city: user.city,
                apartment: user.apartment,
                state: user.state,
                phoneNumber: user.phoneNumber,
                password: user.password,
            };

            return await axios.post(`${baseURL}/user/updateProfile`, body, config);
        } catch (error) {
            console.error('Error updating profile:', error);
            throw error;
        }
    },
    changePassword: async (user) => {
        try {
            const config = {
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            };
            const body = {
                email: user.email,
                oldPassword: user.oldPassword,
                newPassword: user.newPassword,
                confirmPassword: user.confirmPassword,
            };
            return await axios.post(`${baseURL}/user/changePassword`, body, config);
        } catch (error) {
            console.error('Error changing password:', error);
            throw error;
        }
    }
};

export default ProfileService;