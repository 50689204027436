// CheckoutComponent.js
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import './CheckoutStyles.css';
import {editUser} from '../../features/user/userSlice';
import ShippingService from "../../api/ShippingService";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import ErrorModal from '../../modals/ErrorModal';
import OrderAgreementModal from '../../modals/OrderAgreementModal';
import {Helmet} from "react-helmet-async";
import UseOrderProcessing from "../../hooks/useOrderProcessing";
import OrderService, {submitEditOrder} from "../../api/OrderService";
import SuccessModal from "../../modals/SuccessModal";
import {clearRevisedOrder, emptyCart} from "../../features/cart/cartSlice";
import {Spinner} from "react-bootstrap";

const CheckoutComponent = () => {
    const dispatch = useDispatch();
    const userIsLoggedIn = UserLoggedIn();
    const location = useLocation();
    const user = useSelector(state => state.user.user || {});
    const cartItems = useSelector(state => state.cart.cart);
    const cabinetsWithCoordinates = useSelector(state => state.cart.cabinetsWithCoordinates);
    const [editMode, setEditMode] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [orderId, setOrderId] = useState(null);
    const revisedOrderInfo = useSelector(state => state.cart.revisedOrderInfo)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showAgreementModal, setShowAgreementModal] = useState(false);
    const [successOrderId, setSuccessOrderId] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(useSelector(state => state.cart.paymentSuccess));
    const [specialInstructions, setSpecialInstructions] = useState(useSelector(state => state.cart.specialInstructions));

    const token = useSelector(state => state.user.accessToken);
    const [processedOrder, setProcessedOrder] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorFields, setErrorFields] = useState([]);
    const baseURL = process.env.REACT_APP_BASEURL;
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const {orderProcessing} = UseOrderProcessing();
    const revisedOrderId = location.state?.orderId;
    const isRevisedOrder = useSelector(state => state.cart.isRevisedOrder);

    const [orderInfo, setOrderInfo] = useState({
        orderId: isRevisedOrder ? revisedOrderInfo.orderId : '',
        projectName: revisedOrderInfo?.projectName ? revisedOrderInfo?.projectName : '',
        purchaseOrder: revisedOrderInfo?.purchaseOrder ? revisedOrderInfo?.purchaseOrder : '',
        projectAddress1: revisedOrderInfo?.projectAddress1 ? revisedOrderInfo?.projectAddress1 : '',
        projectAddress2: revisedOrderInfo?.projectAddress2 ? revisedOrderInfo?.projectAddress2 : '',
        projectCity: revisedOrderInfo?.projectCity ? revisedOrderInfo?.projectCity : '',
        projectState: revisedOrderInfo?.projectState ? revisedOrderInfo?.projectState : '',
        projectZipcode: revisedOrderInfo?.projectZipcode ? revisedOrderInfo?.projectZipcode : '',
        includeDrawerboxes: revisedOrderInfo?.includeDrawerboxes ? revisedOrderInfo?.includeDrawerboxes : 'false',
        includeHardware: revisedOrderInfo?.includeHardware ? revisedOrderInfo?.includeHardware : 'false',
        includeAssembly: revisedOrderInfo?.includeAssembly ? revisedOrderInfo?.includeAssembly : 'false',
        includeFinishing: revisedOrderInfo?.includeFinishing ? revisedOrderInfo?.includeFinishing : 'false',
        paymentIntent: '',

    });
    const [showProjectAddress, setShowProjectAddress] = useState(false);
    const [emptyFields, setEmptyFields] = useState([]);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"})
        console.log(specialInstructions)
    })

    useEffect(() => {
        setPaymentSuccess(paymentSuccess);
    }, [paymentSuccess]);

    const [userInfo, setUserInfo] = useState({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        address1: user.address1 || '',
        address2: user.address2 || '',
        apartment: user.apartment || '',
        city: user.city || '',
        state: user.state || '',
        zipcode: user.zipcode || '',
    });

    useEffect(() => {

        setUserInfo({
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            phoneNumber: user.phoneNumber || '',
            address1: user.address1 || '',
            address2: user.address2 || '',
            apartment: user.apartment || '',
            city: user.city || '',
            state: user.state || '',
            zipcode: user.zipcode || '',
        });

        ShippingService.getAllAddresses(user.email)
            .then((res) => {
                setAddresses(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [user]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleOrderInfoChange = (e) => {
        const {name, value} = e.target;
        setOrderInfo({...orderInfo, [name]: value});
    };

    const handleUpdate = (fromSelectAddress) => {
        dispatch(editUser(userInfo));

        if (!fromSelectAddress) {
            toggleEditMode();
            ShippingService.addAddress(userInfo)
                .then((res) => {

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const handleSelectedAddress = (address) => {
        userInfo.address1 = address.address1;
        userInfo.address2 = address.address2;
        userInfo.apartment = address.apartment;
        userInfo.city = address.city;
        userInfo.state = address.state;
        userInfo.zipcode = address.zipcode;
        handleUpdate(true);
    };


    const validateFields = () => {
        const requiredFields = [
            'projectName',
            'purchaseOrder',
        ];

        if (showProjectAddress) {
            requiredFields.push(
                'projectAddress1',
                'projectCity',
                'projectState',
                'projectZipcode',
            );
        }

        const emptyFields = requiredFields.filter(field => {
            if (field === 'purchaseOrder' || field.startsWith('project')) {
                return orderInfo[field] ? orderInfo[field].trim() === '' : true;
            } else {
                return userInfo[field] ? userInfo[field].trim() === '' : true;
            }
        });

        setEmptyFields(emptyFields);
        if (emptyFields.length > 0) {
            setErrorMessage('Please fill in all required fields.');
            setErrorFields(emptyFields);
            setShowErrorModal(true);
        }

        return emptyFields.length === 0;
    };
    const handleCloseModal = () => {
        setShowSuccessModal(false);
        localStorage.removeItem("savedCabinets");
        localStorage.removeItem("roomName");
        navigate('/');
    };

    const handleSubmitOrder = async () => {
        if (!validateFields()) {
            return;
        }

        const orders = orderProcessing(orderInfo);

        if (isRevisedOrder)
            setProcessedOrder(orders);
        setShowAgreementModal(true);
    };

    const handleAgreeOrder = async () => {
        setShowAgreementModal(false);
        setIsLoading(true)
        if (!isRevisedOrder) {
            const order = orderProcessing(orderInfo);
            const orderResponse = await OrderService(order, token, cabinetsWithCoordinates);
            setProcessedOrder(orderResponse.data)

            if (orderResponse.status === 201) {
                setIsLoading(false)
                setShowSuccessModal(true); // Show the success modal
                dispatch(emptyCart());
            }
        }

        // setShowPaymentModal(true);

        else {
            const orderResponse = await submitEditOrder(processedOrder, token);
            if (orderResponse.status === 200) {
                setIsLoading(false);
                dispatch(emptyCart())
                dispatch(clearRevisedOrder())
                setSuccessOrderId(processedOrder.orderId);
                setShowSuccessModal(true);
            }
        }
    };

    const handleClosePaymentModal = () => {
        setShowPaymentModal(false);
    };

    const handleToggleProjectAddress = () => {
        setShowProjectAddress(!showProjectAddress);
    };

    const handleUpdateOrder = () => {
        const updatedOrder = {
            ...orderInfo,
        };

        axios.put(`${baseURL}/api/orders/${orderId}`, updatedOrder)
            .then(res => {
                // console.log('Order updated successfully');
            })
            .catch(error => {
                console.error('Error updating order:', error);
            });
    };

    return (
        <div>
            <NavbarLoggedInComponent/>
            <div className="container-fluid min-vh-100">
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <p className="mt-2">Processing your order...</p>
                    </div>
                ) : (
                    <>
                        <Helmet>
                            <title>Secure Checkout | The Sealab - Kitchen & Pantry Cabinets
                            </title>
                            <meta name="description"
                                  content="Complete your purchase securely at The Sealab. Buy high-quality kitchen and closet cabinets. Fast and safe checkout process."/>
                            <meta name="keywords"
                                  content="secure checkout, buy kitchen cabinets, buy closet cabinets, online cabinet store, fast checkout, The Sealab, cabinet purchase"/>
                            <meta property="og:title"
                                  content="Secure Checkout | The Sealab - Kitchen & Closet Cabinets"/>
                            <meta property="og:description"
                                  content="Complete your purchase securely at The Sealab. Buy high-quality kitchen and closet cabinets. Fast and safe checkout process."/>
                            <meta property="og:url" content="https://www.thesealab.com/checkout"/>
                            <meta property="og:type" content="website"/>
                        </Helmet>

                        <div className="row justify-content-center">
                            <div className="col-md-5 user-info">
                                <h2>Order Information</h2>
                                <div className="order-info-form">
                                    <label className="order-info-label">Project Name*</label>
                                    <input type="text" name="projectName"
                                           className={`order-info-input ${emptyFields.includes('projectName') ? 'empty-field' : ''}`}
                                           value={orderInfo.projectName} onChange={handleOrderInfoChange} required/>
                                    <label className="order-info-label">Purchase Order #*</label>
                                    <input type="text" name="purchaseOrder"
                                           className={`order-info-input ${emptyFields.includes('purchaseOrder') ? 'empty-field' : ''}`}
                                           value={orderInfo.purchaseOrder} onChange={handleOrderInfoChange} required/>
                                    {!showProjectAddress && (
                                        <a href="#" onClick={handleToggleProjectAddress}
                                           className="project-address-link">Add
                                            Project Address?</a>
                                    )}
                                    {showProjectAddress && (
                                        <>
                                            <label className="order-info-label">Project Address 1*</label>
                                            <input type="text" name="projectAddress1"
                                                   className={`order-info-input ${emptyFields.includes('projectAddress1') ? 'empty-field' : ''}`}
                                                   value={orderInfo.projectAddress1} onChange={handleOrderInfoChange}
                                                   required/>
                                            <label className="order-info-label">Project Address 2</label>
                                            <input type="text" name="projectAddress2" className="order-info-input"
                                                   value={orderInfo.projectAddress2} onChange={handleOrderInfoChange}/>
                                            <label className="order-info-label">Project City*</label>
                                            <input type="text" name="projectCity"
                                                   className={`order-info-input ${emptyFields.includes('projectCity') ? 'empty-field' : ''}`}
                                                   value={orderInfo.projectCity} onChange={handleOrderInfoChange}
                                                   required/>
                                            <label className="order-info-label">Project State*</label>
                                            <input type="text" name="projectState"
                                                   className={`order-info-input ${emptyFields.includes('projectState') ? 'empty-field' : ''}`}
                                                   value={orderInfo.projectState} onChange={handleOrderInfoChange}
                                                   required/>
                                            <label className="order-info-label">Project Zipcode*</label>
                                            <input type="text" name="projectZipcode"
                                                   className={`order-info-input ${emptyFields.includes('projectZipcode') ? 'empty-field' : ''}`}
                                                   value={orderInfo.projectZipcode} onChange={handleOrderInfoChange}
                                                   required/>
                                            <a href="#" onClick={handleToggleProjectAddress}
                                               className="project-address-link">Remove
                                                Project Address</a>
                                        </>
                                    )}
                                    <div className="radio-group">
                                        <label className="radio-label">Include Drawerboxes?</label>
                                        <div className="radio-options">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeDrawerboxes"
                                                    value="false"
                                                    checked={revisedOrderInfo?.includeDrawerboxes === 'false' || orderInfo?.includeDrawerboxes === 'false'}
                                                    onChange={handleOrderInfoChange}
                                                />
                                                No
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeDrawerboxes"
                                                    value="true"
                                                    // checked={orderInfo.includeDrawerboxes === 'true'}
                                                    checked={revisedOrderInfo?.includeDrawerboxes === 'true' || orderInfo?.includeDrawerboxes === 'true'}
                                                    onChange={handleOrderInfoChange}
                                                />
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div className="radio-group">
                                        <label className="radio-label">Include Hardware? (Coming Soon)</label>
                                        <div className="radio-options">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeHardware"
                                                    value="false"
                                                    checked={orderInfo.includeHardware === 'false'}
                                                    onChange={handleOrderInfoChange}
                                                    disabled={true}
                                                />
                                                No
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeHardware"
                                                    value="true"
                                                    checked={orderInfo.includeHardware === 'true'}
                                                    onChange={handleOrderInfoChange}
                                                    disabled={true}
                                                />
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div className="radio-group">
                                        <label className="radio-label">Include Assembly? (Coming Soon)</label>
                                        <div className="radio-options">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeAssembly"
                                                    value="false"
                                                    checked={orderInfo.includeAssembly === 'false'}
                                                    onChange={handleOrderInfoChange}
                                                    disabled={true}
                                                />
                                                No
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeAssembly"
                                                    value="true"
                                                    checked={orderInfo.includeAssembly === 'true'}
                                                    onChange={handleOrderInfoChange}
                                                    disabled={true}
                                                />
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                    <div className="radio-group">
                                        <label className="radio-label">Include Finishing? (Coming Soon)</label>
                                        <div className="radio-options">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeFinishing"
                                                    value="false"
                                                    checked={orderInfo.includeFinishing === 'false'}
                                                    onChange={handleOrderInfoChange}
                                                    disabled={true}
                                                />
                                                No
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="includeFinishing"
                                                    value="true"
                                                    checked={orderInfo.includeFinishing === 'true'}
                                                    onChange={handleOrderInfoChange}
                                                    disabled={true}
                                                />
                                                Yes
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h2>Local pickup only at this time</h2>
                                </div>
                            </div>
                            {/*<div className="checkout-container">*/}
                            {/*    {cartItems.length === 0 ? (*/}
                            {/*        <h2 className="empty-cart-message">Your cart is empty</h2>*/}
                            {/*    ) : (*/}
                            {/*        <ul className="checkout-items">*/}
                            {/*            {cartItems.map((item, index) => (*/}
                            {/*                <li key={item.itemId}*/}
                            {/*                    className={`list-group-item checkout-item ${index !== cartItems.length - 1 ? 'border-bottom' : ''}`}>*/}
                            {/*                    <div className="d-flex align-items-center">*/}
                            {/*                        <img src={`${imageURL}${item.serialNumber}.webp`} alt={item.name}*/}
                            {/*                             className="checkout-item-image"/>*/}
                            {/*                        <div className="checkout-item-details">*/}
                            {/*                            <h5 className="checkout-item-name">{item.name}</h5>*/}
                            {/*                            {item?.positionName && (*/}
                            {/*                                <p className="checkout-item-text">*/}
                            {/*                                    <strong>Item Name: </strong> {item.positionName}*/}
                            {/*                                </p>*/}
                            {/*                            )}*/}
                            {/*                            <p className="checkout-item-text"><strong>Serial*/}
                            {/*                                Number:</strong> {item.serialNumber}</p>*/}
                            {/*                            <p className="checkout-item-text">*/}
                            {/*                                <strong>Dimensions: </strong>*/}
                            {/*                                {item.width}in*/}
                            {/*                                {item.serialNumber.startsWith("LP") && !item.serialNumber.startsWith('LP_SP') ? '' : ` x ${item.height}in`}*/}
                            {/*                                {item.serialNumber.startsWith("LP_SP") ? '' : ` x ${item.depth}in`}*/}
                            {/*                            </p>*/}

                            {/*                            {item?.caseEdge !== '0' && (*/}
                            {/*                                <>*/}
                            {/*                                    <p className="checkout-item-text"><strong>Case*/}
                            {/*                                        Material:</strong> {item.caseMaterial}</p>*/}
                            {/*                                    /!*<p className="checkout-item-text"><strong>Front*!/*/}
                            {/*                                    /!*    Material:</strong> {item.frontMaterial}</p>*!/*/}
                            {/*                                    <p className="checkout-item-text"><strong>Back*/}
                            {/*                                        Panel:</strong> {item.backPanel}</p>*/}
                            {/*                                    {item?.backPanelMaterial !== null && item?.matBack !== '0' && (*/}
                            {/*                                        <>*/}
                            {/*                                            <p className="checkout-item-text">*/}
                            {/*                                                <strong>Back Panel*/}
                            {/*                                                    Material: </strong> {item?.backPanelMaterial}*/}
                            {/*                                            </p>*/}
                            {/*                                        </>*/}
                            {/*                                    )}*/}
                            {/*                                </>*/}
                            {/*                            )}*/}

                            {/*                            {item?.frontEdge !== '0' && (*/}
                            {/*                                <>*/}
                            {/*                                    <p className="checkout-item-text"><strong>Front*/}
                            {/*                                        Material:</strong> {item.frontMaterial}</p>*/}
                            {/*                                </>*/}
                            {/*                            )}*/}

                            {/*                            {item.drawerType !== 'false' || item.drawerType !== '' &&*/}
                            {/*                                <p className="checkout-item-text"><strong>Drawer*/}
                            {/*                                    Type:</strong> {item.drawerType}</p>}*/}
                            {/*                            /!*{item.hingeSide && <p className="checkout-item-text"><strong>Hinge*!/*/}
                            {/*                            /!*    Side:</strong> {item.hingeSide}</p>}*!/*/}
                            {/*                            {item.gapControl === '1' &&*/}
                            {/*                                <p className="checkout-item-text"><strong>Gap*/}
                            {/*                                    Top:</strong> {item.gapTop}", <strong>Gap*/}
                            {/*                                    Bottom:</strong> {item.gapBottom}", <strong>Gap*/}
                            {/*                                    Left:</strong> {item.gapLeft}", <strong>Gap*/}
                            {/*                                    Right:</strong> {item.gapRight}"</p>}*/}

                            {/*                            {item.edge1 &&*/}
                            {/*                                <p><strong>Edge 1: </strong>{item.edge1}</p>*/}
                            {/*                            }*/}
                            {/*                            /!*{item.caseEdge &&*!/*/}
                            {/*                            /!*    <p><strong>Edgebanding Type: </strong>{item.caseEdge}</p>*!/*/}
                            {/*                            /!*}*!/*/}
                            {/*                            {item.quantity &&*/}
                            {/*                                <p><strong>Quantity: < /strong>{item.quantity}</p>*/}
                            {/*                            }*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </li>*/}
                            {/*            ))}*/}
                            {/*        </ul>*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            <div className="checkout-container">
                                {cartItems.length === 0 ? (
                                    <h2 className="empty-cart-message">Your cart is empty</h2>
                                ) : (
                                    <ul className="checkout-items">
                                        {[...cartItems]
                                            .sort((a, b) => {
                                                const nameA = (a.positionName || '').toLowerCase();
                                                const nameB = (b.positionName || '').toLowerCase();
                                                return nameA.localeCompare(nameB);
                                            })
                                            .map((item, index) => (
                                                <li key={item.itemId}
                                                    className={`list-group-item checkout-item ${index !== cartItems.length - 1 ? 'border-bottom' : ''}`}>
                                                    <div className="d-flex align-items-center">
                                                        <img src={`${imageURL}${item.serialNumber}.webp`}
                                                             alt={item.name}
                                                             className="checkout-item-image"/>
                                                        <div className="checkout-item-details">
                                                            <h5 className="checkout-item-name">{item.name}</h5>
                                                            {item?.positionName && (
                                                                <p className="checkout-item-text">
                                                                    <strong>Item Name: </strong> {item.positionName}
                                                                </p>
                                                            )}
                                                            <p className="checkout-item-text"><strong>Serial
                                                                Number:</strong> {item.serialNumber}</p>
                                                            <p className="checkout-item-text">
                                                                <strong>Dimensions: </strong>
                                                                {item.width}in
                                                                {item.serialNumber.startsWith("LP") && !item.serialNumber.startsWith('LP_SP') ? '' : ` x ${item.height}in`}
                                                                {item.serialNumber.startsWith("LP_SP") ? '' : ` x ${item.depth}in`}
                                                            </p>

                                                            {item?.caseEdge !== '0' && (
                                                                <>
                                                                    <p className="checkout-item-text"><strong>Case
                                                                        Material:</strong> {item.caseMaterial}</p>
                                                                    {/*<p className="checkout-item-text"><strong>Front*/}
                                                                    {/*    Material:</strong> {item.frontMaterial}</p>*/}
                                                                    <p className="checkout-item-text"><strong>Back
                                                                        Panel:</strong> {item.backPanel}</p>
                                                                    {item?.backPanelMaterial !== null && item?.matBack !== '0' && (
                                                                        <>
                                                                            <p className="checkout-item-text">
                                                                                <strong>Back Panel
                                                                                    Material: </strong> {item?.backPanelMaterial}
                                                                            </p>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}

                                                            {item?.frontEdge !== '0' && (
                                                                <>
                                                                    <p className="checkout-item-text"><strong>Front
                                                                        Material:</strong> {item.frontMaterial}</p>
                                                                </>
                                                            )}

                                                            {item.drawerType !== 'false' || item.drawerType !== '' &&
                                                                <p className="checkout-item-text"><strong>Drawer
                                                                    Type:</strong> {item.drawerType}</p>}
                                                            {/*{item.hingeSide && <p className="checkout-item-text"><strong>Hinge*/}
                                                            {/*    Side:</strong> {item.hingeSide}</p>}*/}
                                                            {item.gapControl === '1' &&
                                                                <p className="checkout-item-text"><strong>Gap
                                                                    Top:</strong> {item.gapTop}", <strong>Gap
                                                                    Bottom:</strong> {item.gapBottom}", <strong>Gap
                                                                    Left:</strong> {item.gapLeft}", <strong>Gap
                                                                    Right:</strong> {item.gapRight}"</p>}

                                                            {item.edge1 &&
                                                                <p><strong>Edge 1: </strong>{item.edge1}</p>
                                                            }
                                                            {/*{item.caseEdge &&*/}
                                                            {/*    <p><strong>Edgebanding Type: </strong>{item.caseEdge}</p>*/}
                                                            {/*}*/}
                                                            {item.quantity &&
                                                                <p><strong>Quantity: < /strong>{item.quantity}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className="submit-order-button-container">
                            <button className="btn btn-dark submit-order-button" onClick={handleSubmitOrder}>Request
                                Quote
                            </button>
                        </div>
                        <OrderAgreementModal
                            show={showAgreementModal}
                            onHide={() => setShowAgreementModal(false)}
                            onAgree={handleAgreeOrder}
                        />
                        {/*<PaymentModal*/}
                        {/*    show={showPaymentModal}*/}
                        {/*    onHide={handleClosePaymentModal}*/}
                        {/*    order={processedOrder}*/}
                        {/*    price={25}*/}
                        {/*    processingFee={true}*/}
                        {/*    invoicePayment={false}*/}
                        {/*/>*/}
                        <ErrorModal
                            show={showErrorModal}
                            handleClose={() => setShowErrorModal(false)}
                            title="Missing Information"
                            message={errorMessage}
                            fields={errorFields}
                        />
                        <SuccessModal
                            show={showSuccessModal}
                            handleClose={() => handleCloseModal()}
                            title="Order Placed Successfully"
                            message={
                                <>
                                    Thank you for placing your order with The Sealab!<br/>
                                    Your Order Number
                                    is: <strong>{isRevisedOrder ? processedOrder?.orderId : processedOrder}</strong>.<br/>
                                    You will receive an email shortly when your order has been processed.
                                </>
                            }
                        />
                    </>
                )}
            </div>
        </div>
    );

};

export default CheckoutComponent;




