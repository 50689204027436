import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import PaymentModal from './PaymentModal';

const EditOrderAgreement = ({show, orderId, onHide, onAgree, outOfRevisions}) => {
    const [agree, setAgree] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const handleCheckboxChange = () => {
        setAgree(!agree);
    };

    const handlePayNow = () => {
        setShowPaymentModal(true);
    };

    const handleAgree = () => {
        onAgree();
        onHide();
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{outOfRevisions ? "Out of Revisions" : "Edit Order Agreement"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {outOfRevisions ? (
                        <>
                            <p>You are out of revisions. To continue editing your order, you will need to purchase
                                additional revisions.</p>
                        </>
                    ) : (
                        <>
                            <p>Order Revision Notice</p>
                            <p>By clicking "Edit Order," you agree that:</p>
                            <ol>
                                <li>Your current invoice will be voided and the order returned to the cart for
                                    editing.
                                </li>
                                <li>Any changes will require reprocessing, which may affect pricing.</li>
                                <li>You have up to two free revisions; additional revisions will incur a new processing
                                    fee.
                                </li>
                                <li>Reprocessing typically takes one business day.</li>
                            </ol>
                            <p>Please ensure all changes are final before resubmitting. Contact support with any
                                questions.</p>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="agreeCheckbox"
                                       onChange={handleCheckboxChange}/>
                                <label className="form-check-label" htmlFor="agreeCheckbox">I understand and
                                    agree</label>
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {outOfRevisions ? (
                        <Button variant="dark" onClick={handlePayNow}>Pay Now</Button>
                    ) : (
                        <>
                            <Button variant="secondary" onClick={onHide}>Cancel</Button>
                            <Button className="btn btn-dark" variant="dark" onClick={handleAgree} disabled={!agree}>EDIT
                                ORDER</Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            {outOfRevisions && (
                <PaymentModal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} orderId={orderId}
                              price={25} processingFee={false} invoicePayment={false}/>
            )}
        </>
    );
};

export default EditOrderAgreement;
