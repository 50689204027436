// ErrorModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ErrorModal = ({ show, handleClose, title, message, fields }) => {
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
                <ul>
                    {fields.map((field, index) => (
                        <li key={index}>{capitalize(field.replace(/([A-Z])/g, ' $1').trim())}</li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} style={{ backgroundColor: 'black', color: 'white' }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
