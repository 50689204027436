import React, {useState} from 'react';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import './ImageCarouselStyles.css';
import {Helmet} from "react-helmet-async";

const ImageCarousel = ({cabinet}) => {
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const baseImage = `${imageURL}${cabinet?.serialNumber}.webp`;
    const nfImage = `${imageURL}${cabinet?.serialNumber}_NF.webp`;
    const images = cabinet.serialNumber?.startsWith('LP') ? [baseImage] : [baseImage, nfImage];

    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="image-carousel-container">
            <Helmet>
                <link rel="preload" as="image" href="https://www.thesealab.com/images/BC_2D1DR_L_1766.webp"/>
                <link rel="preload" as="image" href="https://www.thesealab.com/images/PC_2D_3ID_1310.webp"/>
                <link rel="preload" as="image" href="https://www.thesealab.com/images/BC_S_2DR1BF_1017.webp"/>


            </Helmet>
            <div className="image-container">
                <img
                    src={images[currentImageIndex]}
                    className="card-img-top sr-cstm-img"
                    alt={`Cabinet ${cabinet?.serialNumber}`}
                    width="300"
                    height="400"
                    loading="eager"
                />
            </div>
            <div className="image-carousel-controls">
                <FaArrowLeft onClick={handlePrevClick} className="carousel-arrow left-arrow"/>
                <FaArrowRight onClick={handleNextClick} className="carousel-arrow right-arrow"/>
            </div>
        </div>
    );
};

export default ImageCarousel;



