import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL;

class ToolTipService {
    // Fetches tooltip explanation text
    getToolTipExplanation(field) {
        return axios.post(`${baseURL}/api/tool-tip/getFileContent`, null, {params: {toolTipName: field}});
    }

    // Fetches image URLs for the tooltip
    getToolTipImages(field) {
        return axios.get(`${baseURL}/api/tool-tip/getToolTipImages`, {params: {field: field}});
    }
}

export default new ToolTipService();
