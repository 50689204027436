import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FaEdit, FaMinusCircle, FaPlusCircle, FaTimes} from 'react-icons/fa';
import {useLocation, useNavigate} from 'react-router-dom';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
import NavbarComponent from '../NavBarComponents/NavbarComponent';
import {
    addSpecialInstructions,
    decrementQuantity,
    emptyCart,
    incrementQuantity,
    reAddToCart,
    removeItem,
    updateItemsInCart
} from '../../features/cart/cartSlice';
import {selectGlobalSettings} from '../../features/globalSettings/globalSettingsSlice';
import './CartStyles.css';
import RegisterModal from "../../modals/RegisterModal";
import {saveOrderForLater} from "../../api/OrderService";
import LoginModal from "../../modals/LoginModal";
import {Helmet} from "react-helmet-async";
import UseOrderProcessing from "../../hooks/useOrderProcessing";
import SuccessModal from "../../modals/SuccessModal";
import ErrorModal from "../../modals/ErrorModal";
import {nanoid} from "@reduxjs/toolkit";

const imageURL = process.env.REACT_APP_IMAGEURL;

const CustomAlert = ({message, onClose}) => (
    <div className="custom-alert">
        <div className="custom-alert-content">
            <p>{message}</p>
            <button onClick={onClose}>OK</button>
        </div>
    </div>
);

const CartComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const cartItems = useSelector(state => state.cart.cart);
    const user = useSelector(state => state.user.user) || {};
    const token = useSelector(state => state.user.accessToken);
    const isRevisedOrder = useSelector(state => state.cart.isRevisedOrder);
    const globalSettings = useSelector(selectGlobalSettings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedItems, setSelectedItemsLocal] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showLogin, setShowLogin] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const {orderProcessing} = UseOrderProcessing();
    const location = useLocation();
    const orderId = location.state?.orderId;
    const revisedOrderInfo = useSelector(state => state.cart.revisedOrderInfo);
    const [editableFields, setEditableFields] = useState({});
    const [errors, setErrors] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredItems, setFilteredItems] = useState(cartItems);
    const [uniqueSerials, setUniqueSerials] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [specialInstructions, setSpecialInstructions] = useState(useSelector(state => state.cart.specialInstructions));


    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"});
        if (Object.keys(user).length !== 0) {
            setEmail(user.email);
        }
    }, [user]);

    useEffect(() => {
        setUniqueSerials([...new Set(cartItems.map(item => item.serialNumber.toUpperCase()))]);
    }, [cartItems]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredItems(cartItems);
            setShowSuggestions(false);
        } else {
            setFilteredItems(
                cartItems.filter(item =>
                    item?.serialNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item?.positionName?.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
        }
    }, [cartItems, searchQuery]);

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedItemsLocal(prevSelected =>
            prevSelected.length === filteredItems.length ? [] : filteredItems.map(item => item.itemId)
        );
    };

    const handleCheckboxChange = (itemId) => {
        setSelectedItemsLocal(prevSelected => {
            const newSelected = prevSelected.includes(itemId)
                ? prevSelected.filter(id => id !== itemId)
                : [...prevSelected, itemId];
            setSelectAll(newSelected.length === cartItems.length);
            return newSelected;
        });
    };

    const handleIncrement = (itemId) => {
        dispatch(incrementQuantity({id: itemId}));
    };

    const handleDecrement = (itemId) => {
        dispatch(decrementQuantity({id: itemId}));
    };

    const handleEdit = (item) => {
        navigate('/order', {state: {items: [item]}});
    };

    const handleRemove = (itemId) => {
        dispatch(removeItem({id: itemId}));
        setSelectedItemsLocal(prevSelected => prevSelected.filter(id => id !== itemId));
        // setSelectAll(false);
    };

    const handleDuplicate = (item) => {
        dispatch(reAddToCart({...item, itemId: nanoid(), positionName: item.positionName, quantity: item.quantity}));

    };

    const handleProceedToCheckout = () => {
        if (userLoggedIn && isRevisedOrder) {
            if (specialInstructions) {
                dispatch(addSpecialInstructions(specialInstructions));
            }
            navigate('/checkout', {state: {orderId: orderId}});
        } else if (userLoggedIn && !isRevisedOrder) {
            if (specialInstructions) {
                dispatch(addSpecialInstructions(specialInstructions));
            }
            navigate('/checkout');
        } else {
            setShowLogin(true);
        }
    };

    const openRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    };

    const handleSaveForLater = async () => {
        const orders = orderProcessing();

        if (!userLoggedIn) {
            setShowLogin(true);
            return;
        }

        try {
            setIsSubmitting(true);
            await saveOrderForLater(orders, token);
            setShowSuccessModal(true);
        } catch (error) {
            console.error("Error submitting orders:", error);
            setErrorFields(["Order processing failed"]);
            setShowErrorModal(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleConfigureMultipleCabinets = () => {
        if (selectedItems.length === 0) {
            setAlertMessage("Please select item(s).");
            return;
        }
        const itemsToConfigure = cartItems.filter(item => selectedItems.includes(item.itemId));
        navigate('/order', {state: {items: itemsToConfigure}});
    };

    const handleCloseAlert = () => {
        setAlertMessage("");
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        dispatch(emptyCart());
        navigate('/user/profile/saved-orders');
    };

    const parseRange = (range) => {
        if (!range) return [0, 100];
        const parts = range.split('-');
        const min = parts[0] ? Number(parts[0]) : 0;
        const max = parts[1] ? Number(parts[1]) : Number(parts[0]);
        if (isNaN(min) || isNaN(max)) {
            return [0, 100];
        }
        return [min, max];
    };

    // Modified handleFieldChange to check for duplicate positionName
    const handleFieldChange = (itemId, field, value) => {
        setEditableFields(prevFields => ({
            ...prevFields,
            [itemId]: {...prevFields[itemId], [field]: value}
        }));

        if (field === 'positionName') {
            const transformedValue = value.replace(/-/g, '_');

            const pattern = /(.*?)(_(\d+)|(\d+))?$/;
            const match = pattern.exec(transformedValue);

            let positionNamePrefix = transformedValue;
            let positionNameNumber = 1;
            let hasLeadingZeros = false;

            if (match) {
                positionNamePrefix = match[1];
                if (match[3]) {
                    positionNameNumber = parseInt(match[3], 10);
                    hasLeadingZeros = match[3].startsWith('0');
                } else if (match[4]) {
                    positionNameNumber = parseInt(match[4], 10);
                }
            }

            // Collect all items with the same prefix in the cart
            const relatedItems = cartItems.filter(item =>
                item.positionName?.startsWith(positionNamePrefix)
            );

            // Generate a set of reserved names for all related items
            const reservedNames = new Set();
            let highestPositionNumber = 1;

            relatedItems.forEach(item => {
                let itemQuantity = item.quantity || 1;
                let baseNumber = 1;

                const itemMatch = pattern.exec(item.positionName);
                if (itemMatch && itemMatch[3]) {
                    baseNumber = parseInt(itemMatch[3], 10);
                } else if (itemMatch && itemMatch[4]) {
                    baseNumber = parseInt(itemMatch[4], 10);
                }

                for (let i = 0; i < itemQuantity; i++) {
                    const reservedName = `${positionNamePrefix}_${String(baseNumber + i).padStart(3, '0')}`;
                    reservedNames.add(reservedName);
                    highestPositionNumber = Math.max(highestPositionNumber, baseNumber + i);
                }
            });

            // Determine the first available name after the reserved range
            let nextAvailableName;
            let nextAvailableNumber = highestPositionNumber + 1;
            do {
                nextAvailableName = `${positionNamePrefix}_${String(nextAvailableNumber).padStart(3, '0')}`;
                nextAvailableNumber++;
            } while (reservedNames.has(nextAvailableName));

            // Check if the entered position name is within the reserved range or is a duplicate
            const isDuplicate = reservedNames.has(transformedValue) || cartItems.some(item => item.positionName === transformedValue && item.itemId !== itemId);

            setErrors(prevErrors => ({
                ...prevErrors,
                [itemId]: {
                    ...prevErrors[itemId],
                    [field]: isDuplicate
                        ? `The position name "${transformedValue}" already exists or is reserved. The next available name is "${nextAvailableName}".`
                        : ''
                }
            }));
        }

        if (field === 'positionName') {
            const transformedValue = value.replace(/-/g, '_');

            const pattern = /(.*?)(_(\d+)|(\d+))?$/;
            const match = pattern.exec(transformedValue);

            let positionNamePrefix = transformedValue;
            let positionNameNumber = 1;
            let hasLeadingZeros = false;
            let useUnderscore = true;

            if (match) {
                positionNamePrefix = match[1];
                if (match[3]) {
                    positionNameNumber = parseInt(match[3], 10);
                    hasLeadingZeros = match[3].startsWith('0');
                    useUnderscore = true; // The original name uses an underscore
                } else if (match[4]) {
                    positionNameNumber = parseInt(match[4], 10);
                    hasLeadingZeros = match[4].startsWith('0');
                    useUnderscore = false; // The original name does not use an underscore
                }
            }

            // Collect all items with the same prefix in the cart
            const relatedItems = cartItems.filter(item =>
                item.positionName?.startsWith(positionNamePrefix)
            );

            // Generate a set of reserved names for all related items
            const reservedNames = new Set();
            let highestPositionNumber = 1;

            relatedItems.forEach(item => {
                let itemQuantity = item.quantity || 1;
                let baseNumber = 1;

                const itemMatch = pattern.exec(item.positionName);
                if (itemMatch && itemMatch[3]) {
                    baseNumber = parseInt(itemMatch[3], 10);
                } else if (itemMatch && itemMatch[4]) {
                    baseNumber = parseInt(itemMatch[4], 10);
                }

                for (let i = 0; i < itemQuantity; i++) {
                    const reservedName = useUnderscore
                        ? `${positionNamePrefix}_${String(baseNumber + i).padStart(3, '0')}` // Use underscore if originally present
                        : `${positionNamePrefix}${String(baseNumber + i).padStart(3, '0')}`; // No underscore if not originally present
                    reservedNames.add(reservedName);
                    highestPositionNumber = Math.max(highestPositionNumber, baseNumber + i);
                }
            });

            // Determine the first available name after the reserved range
            let nextAvailableName;
            let nextAvailableNumber = highestPositionNumber + 1;
            do {
                nextAvailableName = useUnderscore
                    ? `${positionNamePrefix}_${String(nextAvailableNumber).padStart(3, '0')}` // Use underscore if originally present
                    : `${positionNamePrefix}${String(nextAvailableNumber).padStart(3, '0')}`; // No underscore if not originally present
                nextAvailableNumber++;
            } while (reservedNames.has(nextAvailableName));

            // Check if the entered position name is within the reserved range or is a duplicate
            const isDuplicate = reservedNames.has(transformedValue) || cartItems.some(item => item.positionName === transformedValue && item.itemId !== itemId);

            setErrors(prevErrors => ({
                ...prevErrors,
                [itemId]: {
                    ...prevErrors[itemId],
                    [field]: isDuplicate
                        ? `The position name "${transformedValue}" already exists or is reserved. The next available name is "${nextAvailableName}".`
                        : ''
                }
            }));
        } else if (['width', 'height', 'depth'].includes(field)) {
            const [min, max] = parseRange(cartItems.find(item => item.itemId === itemId)[`${field}Range`]);
            const numValue = parseFloat(value);
            setErrors(prevErrors => ({
                ...prevErrors,
                [itemId]: {
                    ...prevErrors[itemId],
                    [field]: (value === '' || (numValue >= min && numValue <= max)) ? '' : `${field.charAt(0).toUpperCase() + field.slice(1)} must be between ${min} and ${max}`
                }
            }));
        }
    };

    const handleFieldSubmit = (itemId, field) => {
        const currentValue = editableFields[itemId]?.[field];
        const originalValue = cartItems.find(item => item.itemId === itemId)[field];

        if (currentValue === undefined || currentValue === '') {
            setEditableFields(prevFields => ({
                ...prevFields,
                [itemId]: {...prevFields[itemId], [field]: originalValue, [`${field}Editable`]: false}
            }));

            return;
        }

        if (!errors[itemId]?.[field]) {
            const updatedItem = {
                ...cartItems.find(item => item.itemId === itemId),
                [field]: currentValue
            };

            dispatch(updateItemsInCart([updatedItem]));
            setEditableFields(prevFields => {
                const newFields = {...prevFields};
                delete newFields[itemId][field];
                newFields[itemId][`${field}Editable`] = false;
                if (Object.keys(newFields[itemId]).length === 0) {
                    delete newFields[itemId];
                }
                return newFields;
            });
        }
    };

    const handleKeyDown = (e, itemId, field) => {
        if (e.key === 'Enter') {
            handleFieldSubmit(itemId, field);
        }
    };

    const handleBlur = (itemId, field) => {
        handleFieldSubmit(itemId, field);
    };

    const handleDisplayClick = (itemId, field) => {
        setEditableFields(prevFields => ({
            ...prevFields,
            [itemId]: {...prevFields[itemId], [`${field}Editable`]: true}
        }));
    };

    const handleSuggestionClick = (serialNumber) => {
        setFilteredItems(cartItems.filter(item => item.serialNumber.toLowerCase() === serialNumber.toLowerCase()));
        setSearchQuery(serialNumber);
        setShowSuggestions(false);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value.trim() === "") {
            setShowSuggestions(false);
        } else {
            setShowSuggestions(true);
        }
    };

    const handleInputFocus = () => {
        if (searchQuery.trim() !== "") setShowSuggestions(true);
    };

    function handlePosSuggestionClick(pos) {
        setFilteredItems(cartItems.filter(item => item?.positionName?.toLowerCase() === pos?.positionName?.toLowerCase()))
        setSearchQuery(pos?.positionName);
        setShowSuggestions(false)
    }

    const handleManageGlobalSettings = () => {
        navigate('/cart/global-settings');
    };

    const handleApplyGlobalSettings = () => {
        if (selectedItems.length === 0) {
            setAlertMessage("Please select item(s) to apply global settings.");
            return;
        }

        const updatedItems = cartItems.map(item => {
            if (selectedItems.includes(item.itemId)) {
                return {
                    ...item,
                    caseMaterial: globalSettings.caseMaterial,
                    frontMaterial: globalSettings.frontMaterial,
                    backPanelMaterial: globalSettings.backPanelMaterial,
                    caseEdge: globalSettings.caseEdge,
                    frontEdge: globalSettings.frontEdge,
                    backPanel: globalSettings?.backPanel,
                    jointMethod: globalSettings?.jointMethod,
                    hingePlate: globalSettings?.hingePlate,
                    drawerType: globalSettings?.drawerType,
                    globalSettingsApplied: true,
                };
            }
            return item;
        });
        dispatch(updateItemsInCart(updatedItems));
    };

    // Add handler for special instructions
    const handleSpecialInstructionsChange = (e) => {
        dispatch(addSpecialInstructions(e.target.value))
        setSpecialInstructions(e.target.value);

    };

    function handleNavDrawingTool() {
        navigate('/drawing')
    }

    return (
        <>
            <div className="cart-full-page min-vh-100">
                {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
                <Helmet>
                    <title>Thesealab.com | Shopping Cart</title>
                    <meta name="description"
                          content="Manage your profile, view past orders, change your password, or logout. All your cabinet management in one place."/>
                    <meta name="keywords"
                          content="user profile, edit profile, view orders, change password, kitchen cabinets, closet cabinets, e-commerce, cabinet store"/>
                </Helmet>
                <div className="cart-container">
                    {cartItems.length > 0 ? (
                        <>
                            <div className="cart-toolbar">
                                <button onClick={() => dispatch(emptyCart())} className="cart-toolbar-btn">Clear Cart
                                </button>
                                <button onClick={handleManageGlobalSettings} className="cart-toolbar-btn">Manage Global
                                    Settings
                                </button>
                                <button onClick={handleApplyGlobalSettings} className="cart-toolbar-btn">Apply Global
                                    Settings
                                </button>
                                {/*<button onClick={handleNavDrawingTool} className="cart-toolbar-btn">Drawing Tool*/}

                                {/*</button>*/}

                            </div>
                            <div className="configure-cabinets-container">
                                <p>
                                    Configure selected cabinets
                                    <button type="button" onClick={handleConfigureMultipleCabinets} style={{
                                        textDecoration: 'underline',
                                        background: 'none',
                                        border: 'none',
                                        color: 'inherit',
                                        cursor: 'pointer'
                                    }}>here.
                                    </button> Only compatible specs apply.
                                </p>
                                <div className="search-container">
                                    <input
                                        type="text"
                                        className="cart-search-input"
                                        placeholder="Search by Serial Number or Item Name"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                    />
                                    {showSuggestions && uniqueSerials.length > 0 && (
                                        <ul className="search-suggestions">
                                            {uniqueSerials
                                                .filter(serial => serial.includes(searchQuery.toUpperCase()))
                                                .map((serial, index) => (
                                                    <li
                                                        key={index}
                                                        className="search-suggestion-item"
                                                        onMouseDown={() => handleSuggestionClick(serial)}
                                                    >
                                                        {serial}
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                                    {showSuggestions && cartItems.length > 0 && (
                                        <ul className="search-suggestions">
                                            {cartItems
                                                .filter(item => item.positionName?.includes(searchQuery))
                                                .map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className="search-suggestion-item"
                                                        onMouseDown={() => handlePosSuggestionClick(item)}
                                                    >
                                                        {item.positionName}
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="select-all-container">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            className="cart-item-checkbox"
                                        />
                                        <label style={{marginLeft: '10px'}}> Select All</label>
                                    </div>
                                </div>
                            </div>
                            <ul className="cart-items">
                                {[...filteredItems]
                                    .sort((a, b) => {
                                        const nameA = (a.positionName || '').toLowerCase();
                                        const nameB = (b.positionName || '').toLowerCase();
                                        return nameA.localeCompare(nameB);
                                    })
                                    .map(item => (
                                        <li key={item.itemId} className="cart-item">
                                            <input
                                                type="checkbox"
                                                checked={selectedItems?.includes(item.itemId)}
                                                onChange={() => handleCheckboxChange(item.itemId)}
                                                className="cart-item-checkbox"
                                            />
                                            <img src={`${imageURL}${item.serialNumber}.webp`} alt={item.name}
                                                 className="cart-item-image"/>
                                            <div className="cart-item-details">
                                                <div className="cart-item-field d-flex align-items-center">
                                                    {item.positionName && (
                                                        <label className="field-label">Item Name:</label>
                                                    )}
                                                    {editableFields[item.itemId]?.positionNameEditable ? (
                                                        <input
                                                            type="text"
                                                            value={editableFields[item.itemId]?.positionName ?? item.positionName}
                                                            onChange={(e) => handleFieldChange(item.itemId, 'positionName', e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, item.itemId, 'positionName')}
                                                            onBlur={() => handleBlur(item.itemId, 'positionName')}
                                                            className="editable-input-short"
                                                            autoFocus
                                                        />
                                                    ) : (
                                                        <span
                                                            className="editable-display"
                                                            onClick={() => handleDisplayClick(item.itemId, 'positionName')}
                                                        >
                                                    {item.positionName}
                                                </span>
                                                    )}
                                                    {errors[item.itemId]?.positionName && (
                                                        <div className="dimension-error">
                                                            {errors[item.itemId].positionName}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="quantity-control">
                                            <span className="quantity-text">
                                                <strong>Quantity:</strong> {item.quantity}
                                            </span>
                                                    <FaMinusCircle className="quantity-icon"
                                                                   onClick={() => handleDecrement(item.itemId)}/>
                                                    <FaPlusCircle className="quantity-icon"
                                                                  onClick={() => handleIncrement(item.itemId)}/>
                                                </div>
                                                <p className="cart-item-text"><strong>Serial
                                                    Number:</strong> {item.serialNumber}</p>
                                                <div className="cart-item-dimensions d-flex">
                                                    {['width', 'height', 'depth'].map((dim) => (
                                                        <div className="dimension-field" key={dim}>
                                                            <label
                                                                className="field-label">{dim.charAt(0).toUpperCase() + dim.slice(1)} (in):</label>
                                                            {editableFields[item.itemId]?.[`${dim}Editable`] ? (
                                                                <input
                                                                    type="text"
                                                                    value={editableFields[item.itemId]?.[dim] ?? item[dim]}
                                                                    onChange={(e) => handleFieldChange(item.itemId, dim, e.target.value)}
                                                                    onKeyDown={(e) => handleKeyDown(e, item.itemId, dim)}
                                                                    onBlur={() => handleBlur(item.itemId, dim)}
                                                                    className="editable-dimension"
                                                                    autoFocus
                                                                />
                                                            ) : (
                                                                <span
                                                                    className="editable-display"
                                                                    onClick={() => handleDisplayClick(item.itemId, dim)}>
                                                            {item[dim]}
                                                        </span>
                                                            )}
                                                            {errors[item.itemId]?.[dim] && (
                                                                <div
                                                                    className="dimension-error">{errors[item.itemId][dim]}</div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="cart-item-actions">
                                                <a href="#!" onClick={() => handleDuplicate(item)}
                                                   className="action-link cart-duplicate-link">Duplicate?</a>
                                                <div className="icon-actions">
                                                    <FaEdit className="action-icon" onClick={() => handleEdit(item)}/>
                                                    <FaTimes className="action-icon"
                                                             onClick={() => handleRemove(item.itemId)}/>
                                                </div>
                                                {item.globalSettingsApplied && (
                                                    <div className='global-settings-applied-text'>
                                                        *GS*
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                            <div className="special-instructions-container">
                                <label htmlFor="special-instructions-textarea">Special Instructions:</label>
                                <textarea
                                    className="special-instructions-textarea"
                                    placeholder="Enter any special instructions for your order..."
                                    value={specialInstructions}
                                    onChange={handleSpecialInstructionsChange}
                                    rows={4}
                                />
                            </div>

                            <div className="submit-order-button-container">

                                <button className="submit-order-button" onClick={handleProceedToCheckout}>
                                    Submit Quote
                                </button>
                                <RegisterModal
                                    fromCheckout={true}
                                    show={showRegister}
                                    handleClose={() => setShowRegister(false)}
                                />
                                <LoginModal
                                    show={showLogin}
                                    handleClose={() => setShowLogin(false)}
                                    handleRegisterOpen={openRegister}
                                />
                            </div>
                            <div className="save-order-button-container">
                                <button className="save-order-button" onClick={handleSaveForLater}
                                        disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Save Order for Later"}
                                </button>
                                <RegisterModal
                                    fromCheckout={true}
                                    show={showRegister}
                                    handleClose={() => setShowRegister(false)}
                                />
                                <LoginModal
                                    show={showLogin}
                                    handleClose={() => setShowLogin(false)}
                                    handleRegisterOpen={openRegister}
                                />
                            </div>
                        </>
                    ) : (
                        <h2 className="empty-cart-message">Your cart is empty</h2>
                    )}
                    {alertMessage && (
                        <CustomAlert message={alertMessage} onClose={handleCloseAlert}/>
                    )}
                    <SuccessModal
                        show={showSuccessModal}
                        handleClose={handleCloseSuccessModal}
                        title="Order Saved"
                        message="Your order has been saved and added to your Saved Orders."
                    />
                    <ErrorModal
                        show={showErrorModal}
                        handleClose={() => setShowErrorModal(false)}
                        title="Error"
                        message="There was an error saving your order. Please try again."
                        fields={errorFields}
                    />
                </div>
            </div>
        </>
    );
}
export default CartComponent;
