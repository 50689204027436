import React, {useState} from 'react';
import {Alert, Button, Form, Modal} from 'react-bootstrap';
import {useSelector} from "react-redux";
import ProfileService from "../api/ProfileService";

function ChangedPasswordModal({show, handleClose}) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [notification, setNotification] = useState(null);

    const user = useSelector(state => state.user);

    const handleOldPasswordChange = (e) => setOldPassword(e.target.value);
    const handleNewPasswordChange = (e) => {
        const value = e.target.value;
        setNewPassword(value);
        validatePassword(value);
        validateConfirmPassword(value, confirmNewPassword);
    };
    const handleConfirmNewPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmNewPassword(value);
        validateConfirmPassword(newPassword, value);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
        if (!regex.test(password)) {
            setPasswordError('Password must be at least 8 characters long, contain a number, and an uppercase letter.');
        } else {
            setPasswordError('');
        }
    };

    const validateConfirmPassword = (newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (passwordError || confirmPasswordError || !newPassword || !confirmNewPassword) {
            // Prevent submission if there are errors or passwords are not entered
            return;
        }

        const changeUserPw = {
            email: user.user.email,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmNewPassword,
            token: user.accessToken
        };

        ProfileService.changePassword(changeUserPw)
            .then((res) => {
                setNotification('Password changed successfully.');
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            })
            .catch((error) => {
                console.log(error);
                setNotification('Incorrect old password. Please try again.');
            });

        setTimeout(() => setNotification(null), 5000); // Clear notification after 5 seconds
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {notification && <Alert variant="success">{notification}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="oldPassword">
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={oldPassword}
                            onChange={handleOldPasswordChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            required
                        />
                        {passwordError && <Form.Text style={{color: 'red'}}>{passwordError}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirmNewPassword">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={confirmNewPassword}
                            onChange={handleConfirmNewPasswordChange}
                            required
                        />
                        {confirmPasswordError && <Form.Text style={{color: 'red'}}>{confirmPasswordError}</Form.Text>}
                    </Form.Group>

                    <div className="d-flex justify-content-between">
                        <Button className="btn btn-dark ch-pw-btns" variant="dark" onClick={handleClose}
                                style={{width: '49%', textAlign: 'center', height: '52px', marginTop: '8px'}}>
                            Close
                        </Button>
                        <Button className="ch-pw-btns" variant="dark" type="submit"
                                style={{width: '49%', textAlign: 'center'}}>
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ChangedPasswordModal;
